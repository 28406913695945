import { NgModule } from '@angular/core';
import { CoreModule } from '@core/core.module';
import { CustomerService } from './services/CustomerService';
import { CustomerFormBuilder } from './services/CustomerFormBuilder';
import { CustomerListComponent } from './components/customers-list/customers-list.component';
import { CustomerEditFormComponent } from '@modules/Customer/components/customer-edit-form/customer-edit-form.component';

@NgModule({
  declarations: [CustomerListComponent, CustomerEditFormComponent],
  imports: [CoreModule],
  exports: [CustomerListComponent, CustomerEditFormComponent],
  providers: [CustomerService, CustomerFormBuilder],
})
export class CustomertModule {}
