import { Injectable } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { FormHelper } from '@core/services/FormHelper';
import {
  IIdentityServerClientResponse, IClientSecret, IRedirectUri, IAllowedScope, ICorsOrigin, IAllowedGrantType, IPostRedirectUri, IProperties,
} from '@modules/IdentityServer/identityServer.model';
import { StringHelper } from '@core/utils/StringHelper';
import { map } from 'lodash';
import { CustomValidators } from '@core/utils/CustomeValidator';
import { regexUrl1, regexUrl2 } from '@config/constants';

@Injectable()
export class IdentityClientFormBuilder extends FormHelper<IIdentityServerClientResponse> {


  createEmpty(): FormGroup {
    return this.fromModel({
      clientId: '',
      clientName: '',
      allowedScopes: [],
      allowedCorsOrigins: [],
      allowedGrantTypes: [],
      protocolType: '',
      clientUri: '',
      logoUri: '',
      requireClientSecret: false,
      requireConsent: false,
      allowRememberConsent: false,
      requirePkce: false,
      allowPlainTextPkce: false,
      alwaysIncludeUserClaimsInIdToken: false,
      updateAccessTokenClaimsOnRefresh: false,
      enableLocalLogin: false,
      allowAccessTokensViaBrowser: false,
      includeJwtId: false,
      alwaysSendClientClaims: false,
      clientSecrets: [],
      redirectUris: [],
      postLogoutRedirectUris: [],
      properties: {},
      identityTokenLifetime: 300,
      accessTokenLifetime: 3600,
      authorizationCodeLifetime: 300,
      absoluteRefreshTokenLifetime: 2592000,
      slidingRefreshTokenLifetime: 1296000,
      consentLifetime: null,
      refreshTokenUsage: 1,
      refreshTokenExpiration: 1,
      accessTokenType: 0,
      clientClaimsPrefix: 'client_',
      pairWiseSubjectSalt: '',
      deleteClientSecretsIds: [],
      deleteAllowedGrantTypesIds: [],
      deletePropertiesIds: [],
      deleteAllowedScopesIds: [],
      deletePostLogoutRedirectUrisIds: [],
      deleteRedirectUrisIds: [],
      deleteAllowedCorsOriginsIds: [],
    });
  }

  fromModel(model: Partial<IIdentityServerClientResponse>): FormGroup {
    return this.builder.group({
      id: [{ value: model.id, disabled: true }],
      clientId: [model.clientId, [
        Validators.required,
        Validators.pattern('[0-9A-Za-z_.-]+'),
        Validators.maxLength(75)],
      ],
      clientName: [model.clientName, [
        Validators.required,
        Validators.pattern('[0-9A-Za-z_-\\s]+'),
        Validators.maxLength(75)],
      ],
      allowedScopes: this.builder.array(
        map(model.allowedScopes, (scope: IAllowedScope) => this.createScope(scope))),
      allowedCorsOrigins: this.builder.array(
        map(model.allowedCorsOrigins, (origin: ICorsOrigin) => this.createCorsOrigin(origin))),
      allowedGrantTypes: this.builder.array(
        map(model.allowedGrantTypes, (grant: IAllowedGrantType) => this.createGrantType(grant))),
      protocolType: [model.protocolType, [Validators.maxLength(75)]],
      clientUri: [model.clientUri, [Validators.minLength(0), Validators.maxLength(75),
      CustomValidators.pattern([regexUrl1, regexUrl2])]],
      logoUri: [model.logoUri, [Validators.minLength(0), Validators.maxLength(75),
      CustomValidators.pattern([regexUrl1, regexUrl2])]],
      requireClientSecret: [model.requireClientSecret],
      requireConsent: [model.requireConsent],
      allowRememberConsent: [model.allowRememberConsent],
      requirePkce: [model.requirePkce],
      allowPlainTextPkce: [model.allowPlainTextPkce],
      alwaysIncludeUserClaimsInIdToken: [model.alwaysIncludeUserClaimsInIdToken],
      updateAccessTokenClaimsOnRefresh: [model.updateAccessTokenClaimsOnRefresh],
      enableLocalLogin: [model.enableLocalLogin],
      allowAccessTokensViaBrowser: [model.allowAccessTokensViaBrowser],
      includeJwtId: [model.includeJwtId],
      alwaysSendClientClaims: [model.alwaysSendClientClaims],
      clientSecrets: this.builder.array(
        map(model.clientSecrets, (secret: IClientSecret) => this.createSecrets(secret))),
      redirectUris: this.builder.array(
        map(model.redirectUris, (redirectUri: IRedirectUri) => this.createRedirectUri(redirectUri))),
      postLogoutRedirectUris: this.builder.array(
        map(model.postLogoutRedirectUris, (postLogoutRedirectUri: IPostRedirectUri) => this.createPostRedirectUri(postLogoutRedirectUri))),
      properties: this.builder.array(
        map(model.properties, (property: IProperties) => this.createProperties(property))),
      identityTokenLifetime: [model.identityTokenLifetime || 300,
      [Validators.min(0), Validators.maxLength(9), Validators.pattern('^[0-9]*$')]],
      accessTokenLifetime: [model.accessTokenLifetime || 3600,
      [Validators.min(0), Validators.maxLength(9), Validators.pattern('^[0-9]*$')]],
      authorizationCodeLifetime: [model.authorizationCodeLifetime || 300,
      [Validators.min(0), Validators.maxLength(9), Validators.pattern('^[0-9]*$')]],
      absoluteRefreshTokenLifetime: [model.absoluteRefreshTokenLifetime || 2592000,
      [Validators.min(0), Validators.maxLength(9), Validators.pattern('^[0-9]*$')]],
      slidingRefreshTokenLifetime: [model.slidingRefreshTokenLifetime || 1296000,
      [Validators.min(0), Validators.maxLength(9), Validators.pattern('^[0-9]*$')]],
      consentLifetime: [model.consentLifetime,
      [Validators.min(0), Validators.maxLength(9), Validators.pattern('^[0-9]*$')]],
      refreshTokenUsage: [model.refreshTokenUsage],
      refreshTokenExpiration: [model.refreshTokenExpiration],
      accessTokenType: [model.accessTokenType],
      clientClaimsPrefix: [model.clientClaimsPrefix, [Validators.maxLength(75)]],
      pairWiseSubjectSalt: [model.pairWiseSubjectSalt, [Validators.maxLength(75)]],
      deleteClientSecretsIds: [model.deleteClientSecretsIds],
      deleteAllowedGrantTypesIds: [model.deleteAllowedGrantTypesIds],
      deletePropertiesIds: [model.deletePropertiesIds],
      deleteAllowedScopesIds: [model.deleteAllowedScopesIds],
      deletePostLogoutRedirectUrisIds: [model.deletePostLogoutRedirectUrisIds],
      deleteRedirectUrisIds: [model.deleteRedirectUrisIds],
      deleteAllowedCorsOriginsIds: [model.deleteAllowedCorsOriginsIds],
    });

  }

  formClientData(model: IIdentityServerClientResponse) {
    model.allowedGrantTypes = StringHelper.toArray(model.allowedGrantTypes);
    model.allowedCorsOrigins = StringHelper.toArray(model.allowedCorsOrigins);
    model.allowedScopes = StringHelper.toArray(model.allowedScopes);
    return model;
  }

  createRedirectUri(redirectUri: any): FormGroup {
    return this.builder.group({
      id: redirectUri.id || 0,
      redirectUri: [redirectUri.redirectUri || '', [Validators.required, Validators.minLength(0), Validators.maxLength(2000),
      CustomValidators.pattern([regexUrl1, regexUrl2])]],
      deleteOperation: 1,
    });
  }

  createPostRedirectUri(postUri: any): FormGroup {
    return this.builder.group({
      id: postUri.id || 0,
      postLogoutRedirectUri: [postUri.postLogoutRedirectUri || '',
      [Validators.required, Validators.minLength(0), Validators.maxLength(2000),
      CustomValidators.pattern([regexUrl1, regexUrl2])]],
      deleteOperation: 1,
    });
  }
  createCorsOrigin(origin: any): FormGroup {
    return this.builder.group({
      id: origin.id || 0,
      origin: [origin.origin || '', [Validators.required, Validators.minLength(0), Validators.maxLength(150),
      CustomValidators.pattern([regexUrl1, regexUrl2])]],
      deleteOperation: 1,
    });
  }
  createGrantType(grant: any): FormGroup {
    return this.builder.group({
      id: grant.id || 0,
      grantType: [grant.grantType || '', [Validators.required, Validators.minLength(0), Validators.maxLength(250)]],
      deleteOperation: 1,
    });
  }
  createScope(scope: any): FormGroup {
    return this.builder.group({
      id: scope.id || 0,
      scope: [scope.scope || '', [Validators.required, Validators.minLength(0), Validators.maxLength(200)]],
      deleteOperation: 1,
    });
  }
  createProperties(property: any): FormGroup {
    return this.builder.group({
      id: property.id || 0,
      key: [property.key || '', [Validators.required, Validators.minLength(0), Validators.maxLength(250)]],
      value: [property.value || '', [Validators.required, Validators.minLength(0), Validators.maxLength(2000)]],
      deleteOperation: 1,
    });
  }
  createSecrets(secret: any): FormGroup {
    return this.builder.group({
      id: secret.id || 0,
      description: [secret.description || '', [Validators.maxLength(2000)]],
      value: [secret.value || '', Validators.required],
      expiration: new Date(secret.expiration || new Date()),
      type: [secret.type || '', [Validators.required, Validators.minLength(0), Validators.maxLength(250)]],
      deleteOperation: 1,
    });
  }

}
