import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormArray, AbstractControl } from '@angular/forms';
import { NgbModalRef, NgbModal, NgbDateAdapter, NgbDateNativeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { ApiResourceFormBuilder } from '@modules/IdentityServer/services/ApiResourceFormBuilder';
import { IApiResource } from '@modules/IdentityServer/identityServer.model';
import { ApiResourceService } from '@modules/IdentityServer/services/ApiResourceService';
import { ExcepionHandlingService } from '@core/services/ExceptionHandlingService';

@Component({
  selector: 'sf-api-resource-edit',
  templateUrl: './api-resource-edit.component.html',
  styleUrls: ['./api-resource-edit.component.scss'],
  providers: [{ provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }],
})
export class ApiResourceEditComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() resource: IApiResource;
  @Input() showFields: IApiResource;
  public apiSecretControls: AbstractControl[];
  public scopeControls: AbstractControl[];
  public showSaveApiSecret = false;
  public showSaveScope = false;
  public editId: number;
  public newRow = false;
  public items: FormArray;
  public key: string;
  public loading = false;
  public submitting = false;
  public modalReference: NgbModalRef;
  public deletedType: string;
  public selectedValue: string;
  public hashValue: string;
  @Input() valid: boolean;
  @Input() showMsg: boolean;
  public isHide = false;
  public flag = false;
  public oldValue: any;
  public validationMsg = false;
  @Input() error: any;
  public duplicateNameValidation = false;

  constructor(
    private formBuilder: ApiResourceFormBuilder,
    private modalService: NgbModal,
    private service: ApiResourceService,
    private exception: ExcepionHandlingService,
  ) { }

  ngOnInit() {
    const apiSecrets = <FormArray>this.form.controls['apiSecrets'];
    const scopeControls = <FormArray>this.form.controls['scopes'];
    this.apiSecretControls = apiSecrets.controls;
    this.scopeControls = scopeControls.controls;
  }

  get controls() {
    return this.form.controls;
  }

  toggle(id: number, key: string) {
    if (this.newRow) {
      const length = this.form.controls[this.key].value.length;
      const control = <FormArray>this.form.controls[this.key];
      control.removeAt(length - 1);
      this.newRow = false;
      this.editId = null;
      this.flag = false;
    }
    this.removeItem(this.key);
    this.hideEdit(this.key);
    this.key = key;
    this.showEdit(key);
    const target = <FormArray>this.form.controls[this.key];
    this.oldValue = target.value[id];
    this.editId = id;
    this.flag = true;
    this.validationMsg = false;
    this.duplicateNameValidation = false;
  }

  resetRedirectUri(key?: string) {
    this.key = key || this.key;
    if (this.newRow) {
      const length = this.form.controls[this.key].value.length;
      const control = <FormArray>this.form.controls[this.key];
      control.removeAt(length - 1);
      this.flag = false;
      this.newRow = false;
    }
    this.removeItem(key);
    this.hideEdit(this.key);
    this.newRow = false;
    this.editId = null;
    this.valid = false;
    this.validationMsg = false;
    this.isHide = false;
    this.duplicateNameValidation = false;
  }

  showEdit(key: string) {
    switch (key) {
      case 'apiSecrets':
        this.showSaveApiSecret = true;
        if (this.newRow) {
          this.items = this.form.controls[key] as FormArray;
          this.items.push(this.formBuilder.createSecrets(''));
        }
        break;
      case 'scopes':
        this.showSaveScope = true;
        if (this.newRow) {
          this.items = this.form.controls[key] as FormArray;
          this.items.push(this.formBuilder.createScopes(''));
        }
        break;
    }
  }

  hideEdit(key: string) {
    switch (key) {
      case 'apiSecrets':
        this.showSaveApiSecret = false;
        break;
      case 'scopes':
        this.showSaveScope = false;
        break;
    }
  }

  addItem(key: string): void {
    if (this.key !== undefined && this.newRow) {
      const length = this.form.controls[this.key].value.length;
      const control = <FormArray>this.form.controls[this.key];
      control.removeAt(length - 1);
      this.flag = false;
      this.newRow = false;
    }
    this.removeItem(this.key);
    this.hideEdit(this.key);
    this.key = key;
    this.newRow = true;
    this.showEdit(key);
    this.editId = this.items.length - 1;
    this.flag = true;
    this.validationMsg = false;
    this.duplicateNameValidation = false;
  }

  delete() {
    const control = <FormArray>this.form.controls[this.key];
    const target = control.controls[this.editId];
    if (target.value['id'] === 0) {
      control.removeAt(this.editId);
    } else {
      target.patchValue({ deleteOperation: 0 });
      this.items = this.form.controls[this.key] as FormArray;
      this.form.controls[this.deletedType].value.push(this.items.value[this.editId]['id']);
    }
    this.editId = null;
    this.modalReference.close();
  }

  duplicateIdentifier() {
    this.duplicateNameValidation = true;
    const control = <FormArray>this.form.controls[this.key];
    const target = control.controls[this.editId];
    let flag = 0;
    for (let i = 0; i < control.value.length; i++) {
      if (control.value[i].name === target.value.name && control.value[i].deleteOperation !== 0) {
        flag++;
      }
    }
    if (flag > 1) {
      this.duplicateNameValidation = true;
      return true;
    }
    this.duplicateNameValidation = false;
    return false;
  }

  update() {
    const control = <FormArray>this.form.controls[this.key];
    const target = control.controls[this.editId];
    if (target.invalid || this.duplicateNameValidation) {
      this.validationMsg = true;
      return;
    }
    this.hideEdit(this.key);
    this.newRow = false;
    this.editId = null;
    this.flag = false;
    this.validationMsg = false;
    this.isHide = false;
  }

  async updateHashValue(i: number) {
    try {
      this.loading = true;
      const hashValue = await this.service.updateHash();
      const control = <FormArray>this.form.controls['apiSecrets'];
      const target = control.controls[this.editId];
      target.patchValue({ value: hashValue.result.value });
      this.hashValue = hashValue.result.key;
      this.isHide = true;
    } catch (err) {
      this.error = this.exception.errorDisplay(err);
    } finally {
      this.loading = false;
    }
  }

  confirmModal(content: any, index: number, key: string, deletedType: string) {
    this.hideEdit(this.key);
    this.removeItem(this.key);
    this.flag = false;
    this.newRow = false;
    this.editId = index;
    this.key = key;
    this.deletedType = deletedType;
    this.modalReference = this.modalService.open(content, { centered: true });
  }

  removeItem(key?: string) {
    if (this.newRow) {
      const length = this.form.controls[this.key].value.length;
      const control = <FormArray>this.form.controls[this.key];
      control.removeAt(length - 1);
    } else if (this.flag) {
      this.key = key || this.key;
      const control = <FormArray>this.form.controls[this.key];
      const target = control.controls[this.editId];
      target.patchValue(this.oldValue);
      this.flag = false;
      this.hideEdit(this.key);
      this.editId = null;
    }
  }

}
