export enum Endpoints {
  Profilelink = 'identity_userprofilelink',
  GetLoggedInUser = 'identity_userinfo',
  Self = 'self',
  Profile = 'identity',
  GetUsers = 'user',
  GetCustomers = 'customer',
  CreateCustomer = 'customer_create',
  UpdateCustomer = 'customer_update',
  DeleteCustomer = 'customer_delete',
  GetProducts = 'product',
  CreateProduct = 'product_create',
  UpdateProduct = 'product_update',
  GetIdentityResources = 'identityresource',
  CreateIdentityResources = 'identityresource_create',
  UpdateIdentityResources = 'identityresource_update',
  GetIdentityServerClient = 'identityclient',
  CreateIdentityServerClient = 'identityclient_create',
  UpdateIdentityServerClient = 'identityclient_update',
  GetApiResources = 'apiresource',
  CreateApiResources = 'apiresource_create',
  UpdateApiResurces = 'apiresource_update',
  AttachProduct = 'customer_attachProduct',
  RemoveProduct = 'customer_detachProduct',
  GetRoles = 'product_role_transformed',
  ReadRoles = 'product_role_readFromFile_transformed',
  UpdateRoles = 'product_role_updateFromFile_transformed',
  CreateUser = 'user_create',
  UpdateUser = 'user_update',
  DeleteUser = 'user_delete',
  EmailVerify = 'user_verfiyemail',
  ForgotPassword = 'user_forgotpassword',
  UpdateLogo = 'product_updateLogo',
  ValidateUserByUserName = 'user_validateuserbyusername',
  UpdateProductLogo = 'product_updateLogo',
  UpdateCustomerLogo = 'customer_updateLogo',
  Localization = 'general_getlocalization',
  GetGrantTypes = 'predefinedgranttypelist',
  UpdateHash = 'identityclient_generatehashsecret',
  getCustomerLogo = 'customer_getLogo',
  getProductLogo = 'product_getLogo',
  SetUserPassword = 'user_setpassword',
  DeleteApiResource = 'apiresource_delete',
  DeleteClientResource = 'identityclient_delete',
  DeleteIdentityResource = 'identityresource_delete',
}

export enum StaticEndpoints {
  Root = '/',
  ApiInfo = '/info',
  Login = '/security/token',
  Logout = '/security/logout',
}


