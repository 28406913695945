import { DataService } from '@core/services/DataService';
import { Endpoints } from '@config/endpoints';
import { Injectable } from '@angular/core';
import { IUserResponse, IUser } from '@modules/Users/user.model';
import { UserMapper } from '@modules/Users/services/UserMapper';
import { ILink, IServerCollectionResponse, ICollectionModel, IServerResponse } from '@core/core.interfaces';
import { ICustomer } from '@modules/Customer/customer.model';
import { find } from 'lodash';

@Injectable()
export class UserService {

  public endpoints: ILink[];
  constructor(private dataService: DataService, private mapper: UserMapper) { }

  async getAll(customer?: ICustomer, pageNumber?: number, searchText: string = ''): Promise<ICollectionModel<IUser>> {
    const data = await this.dataService.request<IServerCollectionResponse<IUserResponse>>(Endpoints.GetUsers, {
      links: customer.links,
      query: {
        pageSize: 10,
        pageNumber: pageNumber,
        username: searchText,
      },
    });
    this.endpoints = data.result.links;
    const records = this.mapper.deserializeMany(data.result.records);
    return {
      records,
      pageInfo: data.pageInfo,
    };
  }

  async create(user: IUser) {
    await this.dataService.request<IServerResponse<IUser>>(Endpoints.CreateUser, {
      links: this.endpoints,
      data: user,
    });
  }

  async update(user: IUser) {
    await this.dataService.request<IServerResponse<IUser>>(Endpoints.UpdateUser, {
      links: user.links,
      data: user,
    });
  }

  async getById(customer: ICustomer, id: string, pageNumber: number) {
    const { records } = await this.getAll(customer, pageNumber);
    const user = find(records, { id });
    if (!user) {
      throw new Error('User not found');
    }

    const data = await this.dataService.request<IServerResponse<IUser>>(Endpoints.Self, {
      links: user.links,
    });
    return data.result;
  }

  async emailVerification(user: IUser) {
    await this.dataService.request<IServerResponse<IUser>>(Endpoints.EmailVerify, {
      links: user.links,
      data: { email: user.username },
    });
  }

  async resetPassword(user: IUser) {
    await this.dataService.request<IServerResponse<IUser>>(Endpoints.ForgotPassword, {
      links: user.links,
      data: { username: user.username },
    });
  }

  async validateUserByUserName(user: IUser) {
    const data = await this.dataService.request<IServerResponse<any>>(Endpoints.ValidateUserByUserName, {
      links: this.endpoints,
      query: {
        username: user.username,
      },
    });
    return data.result;
  }

  async setUserPassword(password: string, user: IUser) {
    return this.dataService.request(Endpoints.SetUserPassword, {
      links: user.links,
      data: { NewPassword: password },
    });
  }

  async delete(user: IUser) {
    await this.dataService.request<IServerResponse<IUser>>(Endpoints.DeleteUser, {
      links: user.links,
    });
  }

}
