import { Injectable } from '@angular/core';
import { IProduct } from '@modules/Product/product.model';
import { FormHelper } from '@core/services/FormHelper';
import { FormGroup, Validators } from '@angular/forms';
import { regexName } from '@config/constants';

@Injectable()
export class ProductFormBuilder extends FormHelper<IProduct> {

  createEmpty(): FormGroup {
    return this.fromModel({
      name: '',
      webpage: '',
      code: 0,
      description: '',
    });
  }

  fromModel(model: Partial<IProduct>): FormGroup {
    return this.builder.group({
      name: [model.name, [Validators.required,
        Validators.pattern(regexName),
        Validators.maxLength(75)],
      ],
      webpage: [model.webpage],
      code: [model.code, [
        Validators.required,
        Validators.pattern('^[0-9]*$'),
        Validators.maxLength(5)],
      ],
      description: [model.description, [Validators.maxLength(1000)]],
    });
  }
}
