import { find } from 'lodash';
import { DataService } from '@core/services/DataService';
import { Endpoints } from '@config/endpoints';
import { Injectable } from '@angular/core';
import { ILink, IServerCollectionResponse, ICollectionModel, IServerResponse } from '@core/core.interfaces';
import { ICustomer } from '@modules/Customer/customer.model';

@Injectable()
export class CustomerService {

  public endpoints: ILink[];
  constructor(private dataService: DataService) { }

  async getAll(pageNumber: number, searchText: string = '', id: string = ''): Promise<ICollectionModel<ICustomer>> {
    const data = await this.dataService.request<IServerCollectionResponse<ICustomer>>(Endpoints.GetCustomers, {
      query: {
        pageSize: 10,
        pageNumber: pageNumber,
        name: searchText,
        id: id,
      },
    });
    this.endpoints = data.result.links;
    return {
      records: data.result.records,
      pageInfo: data.pageInfo,
    };
  }

  async getById(id: string): Promise<ICustomer> {
    const { records } = await this.getAll(1, '', id);
    const client = find(records, { id });
    if (!client) {
      throw new Error('Client not found');
    }
    return client;
  }

  async create(customer: ICustomer) {
    return this.dataService.request<IServerResponse<ICustomer>>(Endpoints.CreateCustomer, {
      data: customer,
    });
  }

  async update(customer: ICustomer) {
    return this.dataService.request<IServerResponse<ICustomer>>(Endpoints.UpdateCustomer, {
      links: customer.links,
      data: customer,
    });
  }

  async addProduct(customer: ICustomer, id: string) {
    const data = await this.dataService.request<IServerResponse<ICustomer>>(Endpoints.AttachProduct, {
      links: customer.links,
      data: { 'productId': id },
    });
    return data;
  }

  async removeProduct(customer: ICustomer, id: string) {
    const data = await this.dataService.request<IServerResponse<ICustomer>>(Endpoints.RemoveProduct, {
      links: customer.links,
      data: { 'productId': id },
    });
    return data;
  }

  async uploadLogo(model: FormData, customer: ICustomer) {
    const result = await this.dataService.request<ICustomer>(Endpoints.UpdateCustomerLogo, {
      links: customer.links,
      data: model,
    });
    return result;
  }

  async getLogo(customer: ICustomer) {
    const data = await this.dataService.request<IServerResponse<ICustomer>>(Endpoints.getCustomerLogo, {
      links: customer.links,
    });
    return data.result;
  }

  async delete(customer: ICustomer) {
    return this.dataService.request<IServerResponse<ICustomer>>(Endpoints.DeleteCustomer, {
      links: customer.links,
    });
  }

}
