<div class="page section-bg">
  <h1>{{ 'FE_General_Lable_Create' | translate }} {{ 'FE_General_Lable_Products' | translate }}</h1>
  <div class="wrapper mt-3">
    <ngb-tabset>
      <ngb-tab title="{{ 'FE_General_Lable_General' | translate }}">
        <ng-template ngbTabContent>
          <div class="content mt-4">
            <form [formGroup]="form" (submit)="onSubmit()" class="col-lg-12">
              <sf-product-edit-form [form]="form" [disableLogoChange]="disableLogoChange" [showMsg]="showMsg" [error]="error"></sf-product-edit-form>
              <div class="actions btn-toolbar mt-3 justify-content-end">
                <div class="mr-3">
                  <div (click)="onSubmit()" class="btn-wrapper"><button class="btn-custom-primary" type="submit" [disabled]="submitting"><label class="btn-lable-primary">{{ 'FE_General_Button_Save' | translate }}</label></button></div>
                </div>
                <div  routerLink="/products" class="btn-wrapper"><button class="btn-custom-secondary" type="button"><label class="btn-lable-secondary">{{ 'FE_General_Button_Cancel' | translate }}</label></button></div>
              </div>
            </form>
          </div>
        </ng-template>
      </ngb-tab>
      <ngb-tab title="{{ 'FE_General_Lable_Roles' | translate }}" disabled="true">
      </ngb-tab>
    </ngb-tabset>
  </div>
</div>
    