import { FormGroup } from '@angular/forms';
import { Component, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { CustomerService } from '@modules/Customer/services/CustomerService';
import { ICustomer } from '@modules/Customer/customer.model';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'sf-customer-edit-form',
  templateUrl: 'customer-edit-form.component.html',
  styleUrls: ['customer-edit-form.component.scss'],
})
export class CustomerEditFormComponent {
  @Input() formCustomer: FormGroup;
  @Input() showMsg: boolean;
  @Input() disableLogoChange: boolean;
  @Input() showAllFields: boolean;
  @Input() imagePath: any;
  @Input() customer: ICustomer;
  @Output() loadingToggle = new EventEmitter<void>();
  @ViewChild('image') image: any;
  public fileToUpload: File = null;
  public imageUrl: any = 'assets/upload.png';
  @Input() error: any;

  constructor(private clientService: CustomerService, private sanitizer: DomSanitizer) { }

  get controls() {
    return this.formCustomer.controls;
  }

  focusIn() {
    this.showMsg = false;
  }


  async handleFileInput(file: FileList) {
    if (!this.disableLogoChange) {
      this.fileToUpload = file.item(0);
      const reader = new FileReader();
      this.imagePath = null;
      reader.onload = (event: any) => {
        this.imageUrl = this.sanitizer.bypassSecurityTrustResourceUrl(event.target.result);
      };
      reader.readAsDataURL(this.fileToUpload);
      this.image.nativeElement.value = null;
    }
  }

  async upload() {
    try {
      if (this.fileToUpload) {
        this.loadingToggle.emit();
        const formData = new FormData();
        formData.append('file', this.fileToUpload);
        await this.clientService.uploadLogo(formData, this.customer);
        this.loadingToggle.emit();
        this.fileToUpload = null;
      }
    } catch (err) { }
  }

}
