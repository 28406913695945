import { Component, OnInit } from '@angular/core';
import { ApiResourceService } from '@modules/IdentityServer/services/ApiResourceService';
import { IApiResource } from '@modules/IdentityServer/identityServer.model';
import { IPageInfoResponse } from '@core/core.interfaces';
import { ExcepionHandlingService } from '@core/services/ExceptionHandlingService';

@Component({
  selector: 'sf-api-resources',
  templateUrl: './api-resources.component.html',
  styleUrls: ['./api-resources.component.scss'],
})
export class ApiResourcesPageComponent implements OnInit {

  public loading = false;
  public apiResources: IApiResource[];
  public error: any = null;
  public pageInfo: IPageInfoResponse;
  public pageNumbers: any;
  public currentPageNumber = 1;
  public collectionSize: number;
  public totalPages: number;
  public searchText: string;

  constructor(
    private service: ApiResourceService,
    private exception: ExcepionHandlingService,
  ) { }

  ngOnInit() {
    this.loadApiResources(1);
  }

  async loadApiResources(pageNumber: number) {
    if (this.apiResources && this.apiResources.length === 1 && pageNumber !== 1) {
      pageNumber--;
      this.currentPageNumber = pageNumber;
    }
    try {
      this.loading = true;
      const result = await this.service.getAll(pageNumber, this.searchText);
      this.apiResources = result.records;
      this.pageInfo = result.pageInfo;
      this.collectionSize = this.pageInfo.totalRecords;
      this.totalPages = this.pageInfo.totalPages;
    } catch (err) {
      this.error = this.exception.errorDisplay(err);
    } finally {
      this.loading = false;
    }
  }

  search(search: string) {
    this.searchText = search;
    this.loadApiResources(1);
  }

}
