import { NgModule } from '@angular/core';
import { CoreModule } from '@core/core.module';
import { ProductService } from './services/ProductService';
import { ProductsListComponent } from './components/products-list/products-list.component';
import { ProductEditFormComponent } from './components/product-edit-form/product-edit-form.component';
import { ProductFormBuilder } from '@modules/Product/services/ProductFormBuilder';

@NgModule({
  declarations: [ProductsListComponent, ProductEditFormComponent],
  imports: [CoreModule],
  exports: [ProductsListComponent, ProductEditFormComponent],
  providers: [ProductService, ProductFormBuilder],
})
export class ProductModule {}
