<div class="page">
  <h1 *ngIf="product" class="mb-3">{{ 'FE_General_Lable_Edit' | translate }} {{product.name}} {{ 'FE_General_Lable_Products' | translate }}</h1>
  <sf-app-loading [loading]="loading"></sf-app-loading>
  <div class="wrapper mt-3" *ngIf="product">
    <ngb-tabset #tabs [activeId]="selectedTab" (tabChange)="onTabChange($event)">
      <ngb-tab id="general" title="{{ 'FE_General_Lable_General' | translate }}">
        <ng-template ngbTabContent>
          <div class="content">
            <form [formGroup]="form" class="mt-3 col-md-12">
              <sf-product-edit-form [form]="form" [productId]="productId" [pageNumber]="pageNumber" [disableLogoChange]="disableLogoChange" 
              [imagePath]="imagePath" (loadingToggle)="loadingToggle()" [error]="error"></sf-product-edit-form>
              <div class="actions btn-toolbar mt-3 justify-content-end">
                <div class="mr-3">
                  <div (click)="onSubmit()" class="btn-wrapper"><button class="btn-custom-primary" type="submit" [disabled]="submitting"><label class="btn-lable-primary">{{ 'FE_General_Button_Save' | translate }}</label></button></div>
                </div>
                <div  routerLink="/products" class="btn-wrapper"><button class="btn-custom-secondary" type="button"><label class="btn-lable-secondary">{{ 'FE_General_Button_Cancel' | translate }}</label></button></div>
              </div>
            </form>
          </div>
        </ng-template>
      </ngb-tab>
      <ngb-tab id="roles" title="{{ 'FE_General_Lable_Roles' | translate }}">
        <ng-template ngbTabContent>
          <div class="content" *ngIf="rolesResult">
            <sf-roles-list [rolesResult]="rolesResult"></sf-roles-list>
            <sf-roles-file-upload [productId]="productId" (loadingToggle)="loadingToggle()"></sf-roles-file-upload>
          </div>
        </ng-template>
      </ngb-tab>
    </ngb-tabset>
  </div>
</div>
