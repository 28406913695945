import { AuthGuard } from '@core/services/AuthGuard';
import { NgModule } from '@angular/core';
import { RouterModule, Route } from '@angular/router';
import { LoginLayoutComponent } from '../app/layouts/login-layout/login-layout.component';
import { ApplicationLayoutComponent } from '../app/layouts/application-layout/application-layout.component';
import { AccessManagerLayoutComponent } from '../app/layouts/access-manager-layout/access-manager-layout.component';
import { LoginCallbackPageComponent } from '../app/pages/login-callback/login-callback.page';
import { CustomersPageComponent } from '../app/pages/customers/customers.page';
import { CustomerCreatePageComponent } from '../app/pages/customer-create/customer-create.page';
import { CustomerEditPageComponent } from '../app/pages/customer-edit/customer-edit.page';
import { ProductCreatePageComponent } from '../app/pages/product-create/product-create.page';
import { ProductEditPageComponent } from '../app/pages/product-edit/product-edit.page';
import { DashboardPageComponent } from '../app/pages/dashboard/dashboard.page';
import { ProductsPageComponent } from '../app/pages/products/products.page';
import { IdentityResourcesListComponent } from '../app/pages/identity-resources/identity-resources.component';
import { IdentityResourcesEditComponent } from '../app/pages/identity-resources-edit/identity-resources-edit.component';
import { IdentityResourcesCreateComponent } from '../app/pages/identity-resources-create/identity-resources-create.component';
import { IdentityClientsListComponent } from '../app/pages/identity-server-clients/identity-server-clients.component';
import { IdentityClientsCreateComponent } from '../app/pages/identity-server-clients-create/identity-server-clients-create.component';
import { IdentityClientsEditComponent } from '../app/pages/identity-server-clients-edit/identity-server-clients-edit.component';
import { ApiResourcesPageComponent } from '../app/pages/api-resources/api-resources.component';
import { ApiResourceCreatePageComponent } from '../app/pages/api-resource-create/api-resource-create.component';
import { ApiResourceEditPageComponent } from '../app/pages/api-resource-edit/api-resource-edit.component';
import { ClientsSelectPageComponent } from '../app/pages/clients-select/clients-select.component';
import { ProductsSelectPageComponent } from '../app/pages/products-select/products-select.component';
import { LogoutLayoutComponent } from '../app/layouts/logout-layout/logout-layout.component';
import { LogoutCallbackPageComponent } from '../app/pages/logout-callback/logout-callback.page';

const routing: Route[] = [
  {
    path: 'callback.html',
    component: LoginLayoutComponent,
    children: [
      { path: '', component: LoginCallbackPageComponent },
    ],
  },
  {
    path: 'signout-callback-oidc',
    component: LogoutLayoutComponent,
    children: [
      { path: '', component: LogoutCallbackPageComponent },
    ],
  },
  {
    path: 'signout',
    component: LogoutLayoutComponent,
    children: [
      { path: '', component: LogoutCallbackPageComponent },
    ],
  },
  {
    path: 'access',
    canActivate: [AuthGuard],
    component: AccessManagerLayoutComponent,
    children: [
      { path: 'select/client-select', component: ClientsSelectPageComponent },
      { path: 'select/product-select', component: ProductsSelectPageComponent },
    ],
  },
  {
    path: '',
    canActivate: [AuthGuard],
    component: ApplicationLayoutComponent,
    children: [
      { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
      { path: 'dashboard', component: DashboardPageComponent },
      { path: 'customers', component: CustomersPageComponent },
      { path: 'customers/new', component: CustomerCreatePageComponent },
      { path: 'customers/:id/:tab', component: CustomerEditPageComponent },
      { path: 'products', component: ProductsPageComponent },
      { path: 'products/new', component: ProductCreatePageComponent },
      { path: 'products/:id/:tab', component: ProductEditPageComponent },
      { path: 'identity-resources', component: IdentityResourcesListComponent },
      { path: 'identity-resources/new', component: IdentityResourcesCreateComponent },
      { path: 'identity-resources/:id', component: IdentityResourcesEditComponent },
      { path: 'identity-clients', component: IdentityClientsListComponent },
      { path: 'identity-clients/new', component: IdentityClientsCreateComponent },
      { path: 'identity-clients/:id', component: IdentityClientsEditComponent },
      { path: 'identity/api-resources', component: ApiResourcesPageComponent },
      { path: 'identity/api-resources/new', component: ApiResourceCreatePageComponent },
      { path: 'identity/api-resources/:id', component: ApiResourceEditPageComponent },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      routing,
    ),
  ],
  exports: [
    RouterModule,
  ],
})
export class RouterConfigurationModule { }
