<div *ngIf="customers!=undefined && customers.length<=0" class="text-center">
  <strong>{{ 'FE_General_Label_NoResult' | translate }}</strong>
</div>
<div class="mt-3 table-responsive table-wraper" [ngClass]="( customers==undefined || customers.length<=0)?'visible-table':'hide-table'">
  <table class="table table-hover">
    <thead>
      <tr>
        <th>{{ 'FE_General_Lable_Name' | translate }}</th>
        <th>{{ 'FE_General_Lable_Products' | translate }}</th>
        <th class="text-center">{{ 'FE_General_Lable_Actions' | translate }}</th>
      </tr>
    </thead>
    <tr *ngFor="let record of customers; let i=index ">
      <td>{{record.name}}</td>
      <td>
        <span *ngFor="let product of record.products; let i=index;">
          <span *ngIf="!i; else showNameByComma">{{product.name}}</span>
          <ng-template #showNameByComma>, {{product.name}}</ng-template>
        </span>
      </td>
      <td class="text-center td-action">
        <div class="btn-group">
          <button class="btn custom-btn" [routerLink]="['/customers/', record.id, 'general']">
            <img src="../../../../assets/images/edit.svg" class="Combined-Shape">
          </button>
          <span class="line-sperator"></span>
          <button class="btn custom-btn" (click)="confirmModal(customercontent,record)">
            <img class="Combined-Shape delete-opacity" src="../../../../assets/images/delete.svg">
          </button>
        </div>
      </td>
    </tr>
  </table>
</div>


<ng-template #customercontent let-close="close" let-dismiss="dismiss">
  <div class="modal-header">
    <span class="delete-icon-back">
      <img src="../../../../assets/images/delete.svg" class="delete-shape">
    </span>
    <span class="modal-title">Delete Item</span>
    <button type="button" class="close" aria-label="Close" (click)="close('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p> Are you sure you want to delete this customer?</p>
  </div>
  <div class="modal-footer">
    <div class="actions btn-toolbar mt-3 justify-content-end">
      <div class="mr-3">
        <div (click)="deleteCustomer()" class="btn-wrapper">
          <button class="btn-custom-primary-modal" type="button">
            <label class="btn-lable-primary-modal">{{ 'FE_General_Button_Ok' | translate }}</label>
          </button>
        </div>
      </div>
      <div (click)="close('Close click')" class="btn-wrapper">
        <button class="btn-custom-secondary-modal" type="button">
          <label class="btn-lable-secondary-modal">{{ 'FE_General_Button_Cancel' | translate }}</label>
        </button>
      </div>
    </div>
  </div>
</ng-template>