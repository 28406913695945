import { Component, OnInit } from '@angular/core';
import { IProductSelect } from '@modules/AccessManager/accessManager.model';

@Component({
  selector: 'sf-products-select',
  templateUrl: './products-select.component.html',
  styleUrls: ['./products-select.component.scss'],
})
export class ProductsSelectPageComponent implements OnInit {

  public productData: IProductSelect[] = [
    {
      'src': '../../../../assets/upload.png',
      'imgCaption': 'Simplifai Admin',
      'alt': 'simplifai admin',
    },
    {
      'src': '../../../../assets/upload.png',
      'imgCaption': 'Digital Assistant',
      'alt': 'digital assistant',
    },
    {
      'src': '../../../../assets/upload.png',
      'imgCaption': 'Invoice Reader',
      'alt': 'invoice reader',
    },
  ];

  constructor() { }

  ngOnInit() {
  }

}
