import { Component, OnInit } from '@angular/core';
import { IClientSelect } from '@modules/AccessManager/accessManager.model';
import { ClientSelectService } from '@modules/AccessManager/services/client-select.service';

@Component({
  selector: 'sf-clients-select',
  templateUrl: './clients-select.component.html',
  styleUrls: ['./clients-select.component.scss'],
})
export class ClientsSelectPageComponent implements OnInit {
  public clientData: IClientSelect[];
  constructor( private service: ClientSelectService) { }

  ngOnInit() {
    this.loadClient();
  }

  async loadClient() {
    const data = await this.service.getAll();
    this.clientData = data;
  }
}
