import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { IIdentityServerClientResponse } from '@modules/IdentityServer/identityServer.model';

@Component({
  selector: 'sf-app-identity-clients-form-others',
  templateUrl: './identity-clients-form-others.component.html',
  styleUrls: ['./identity-clients-form-others.component.scss'],
})
export class IdentityClientsFormOthersComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() client: IIdentityServerClientResponse;
  constructor() { }

  ngOnInit() {
  }

  setDropDownValue(value: number, formControl: string) {
    this.form.controls[formControl].patchValue(value);
  }

}
