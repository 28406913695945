<div class="form-group row mt-3">
  <label for="id" class="control col-sm-2 col-form-label text-field-label" *ngIf="showFields">Id</label>
  <input id="id" class="form-control col-sm-5" [formControl]="form.controls['id']" [attr.disabled]="true" *ngIf="showFields">
</div>
<div class="form-group row">
  <label for="name" class="control col-sm-2 col-md-2 col-form-label text-field-label">Name
    <span class="mandetory-input ml-1">*</span>
  </label>
  <input id="name" class="form-control col-sm-5" [formControl]="form.controls['name']" (keyup)="error=null"
  [ngClass]="{ 'is-invalid': ((form.controls.name.dirty || form.controls.name.touched) && form.controls.name.errors || form.controls.name.untouched && showMsg && form.controls.name.invalid) || error!==null && (error.status===409 || error.status===422)}"
    required>
  <div *ngIf="(form.controls.name.dirty || form.controls.name.touched) && form.controls.name.errors || form.controls.name.untouched && showMsg && form.controls.name.invalid"
    class="invalid-feedback validation-msg">
    <div *ngIf="form.controls.name.errors.required">Name is required</div>
    <div *ngIf="form.controls.name.errors?.pattern">'-' and '_' special characters allowed only(must not include spaces)</div>
    <div *ngIf="form.controls.name.errors?.maxlength && !form.controls.name.errors.pattern">Should not be more than 75 characters</div>
  </div>
  <div *ngIf="error!==null && error.status===409" class="invalid-feedback display-block validation-msg">Name already exists</div>
  <div *ngIf="error!==null && error.status===422" class="invalid-feedback display-block validation-msg">{{error.error.Message.Name}}</div>
</div>
<div class="form-group row">
  <label for="displayName" class="control col-sm-2 col-md-2 col-form-label text-field-label">DisplayName
    <span class="mandetory-input ml-1">*</span>
  </label>
  <input id="displayName" class="form-control col-sm-5" [formControl]="form.controls['displayName']" 
  [ngClass]="{ 'is-invalid':((form.controls.displayName.dirty || form.controls.displayName.touched) && form.controls.displayName.errors || form.controls.displayName.untouched && showMsg && form.controls.displayName.invalid) || error!==null &&  error.status===422}">
  <div *ngIf="(form.controls.displayName.dirty || form.controls.displayName.touched) && form.controls.displayName.errors || form.controls.displayName.untouched && showMsg && form.controls.displayName.invalid"
  class="validation-msg invalid-feedback">
    <div *ngIf="form.controls.displayName.errors.required">Display name is required</div>
    <div *ngIf="form.controls.displayName.errors?.maxlength && !form.controls.displayName.errors.pattern">Should not be more than 75 characters</div>
  </div>
  <div *ngIf="error!==null && error.status===422" class="invalid-feedback display-block validation-msg">{{error.error.Message.DisplayName}}</div>
</div>
<div class="form-group row">
  <label for="description" class="control col-sm-2 col-md-2 col-form-label text-field-label">Description</label>
  <textarea id="description" class="form-control col-sm-5" [formControl]="form.controls['description']"
  [ngClass]="{ 'is-invalid': (form.controls.description.dirty || form.controls.description.touched) && form.controls.description.errors || form.controls.description.untouched && showMsg && form.controls.description.invalid}"></textarea>
  <div *ngIf="(form.controls.description.dirty || form.controls.description.touched) && form.controls.description.errors || form.controls.description.untouched && showMsg && form.controls.description.invalid"
  class="validation-msg invalid-feedback">
    <div *ngIf="form.controls.description.errors?.maxlength">Should not be more than 1000 characters</div>
  </div>
</div>
<div class="form-group row">
  <div class="col-sm-2"></div>
  <div class="ml-md-0 ml-xs-2">
    <input class="form-checkbox" id="checkenable" type="checkbox" [formControl]="form.controls['enabled']">
    <label for="checkenable">Enabled</label>
  </div>
</div>
<div class="row mt-5" *ngIf="form">
  <div class=" col-sm-6 mb-2">
    <label>Scopes</label>
  </div>
  <div class=" col-sm-6 text-right mb-2">
    <button type="button" class="btn-custom-secondary btn-label-secondary" (click)="addItem('scopes')">Add scopes</button>
  </div>
  <div class="table-responsive table-wraper">
    <table class="table table-hover">
      <thead>
        <tr>
          <th>Id</th>
          <th class="w-25">Name</th>
          <th class="w-25">Display Name</th>
          <th class="w-25">Description</th>
          <th class="w-25">Required</th>
          <th class="w-25">Emphasize</th>
          <th class="w-25">Show In Discovery Document</th>
          <th class="text-center">Actions</th>
        </tr>
      </thead>
      <tbody [formGroup]="form">
        <ng-container formArrayName="scopes" *ngFor="let scope of form.get('scopes').controls; let i = index;">
          <tr *ngIf=" scope.get('deleteOperation').value ">
            <td>
              <span title="{{ scope.get('id').value }}">{{ scope.get('id').value}}</span>
            </td>
            <td>
              <span *ngIf="editId==i && showSaveScope">
                <input type="text" [formControl]="scope.controls['name']" (keyup)="duplicateIdentifier()" class="form-control-table" #input [ngClass]="{ 'is-invalid val-margin-top': ((scope.controls.name.dirty || scope.controls.name.touched) && scope.controls.name.errors || scope.controls.name.untouched && validationMsg && scope.controls.name.invalid) || duplicateNameValidation}">
                <div *ngIf="(scope.controls.name.dirty || scope.controls.name.touched) && scope.controls.name.errors || scope.controls.name.untouched && validationMsg && scope.controls.name.invalid" class="table-validation-msg  text-left">
                  <div *ngIf="scope.controls.name.errors?.required">Name is required</div>
                  <div *ngIf="scope.controls.name.errors?.maxlength">Should not be more than 75 characters</div>
                </div>
                <div class="table-validation-msg" *ngIf="duplicateNameValidation">Name already exits</div>
              </span>
              <span *ngIf="editId!==i || !showSaveScope" title="{{ scope.get('name').value }}">{{ scope.get('name').value | slice:0:20}}</span>
            </td>
            <td>
              <span *ngIf="editId==i && showSaveScope">
                <input type="text" [formControl]="scope.controls['displayName']" class="form-control-table" #input [ngClass]="{ 'is-invalid val-margin-top': (scope.controls.displayName.dirty || scope.controls.displayName.touched) && scope.controls.displayName.errors || scope.controls.displayName.untouched && validationMsg && scope.controls.displayName.invalid}">
                <div *ngIf="(scope.controls.displayName.dirty || scope.controls.displayName.touched) && scope.controls.displayName.errors || scope.controls.displayName.untouched && validationMsg && scope.controls.displayName.invalid" class="table-validation-msg  text-left">
                  <div *ngIf="scope.controls.displayName.errors?.required">Display name is required</div>
                  <div *ngIf="scope.controls.displayName.errors?.maxlength">Should not be more than 75 characters</div>
                </div>
              </span>
              <span *ngIf="editId!==i || !showSaveScope" title="{{ scope.get('displayName').value }}">{{ scope.get('displayName').value | slice:0:20}}</span>
            </td>
            <td>
              <span *ngIf="editId==i && showSaveScope">
                <input type="text" [formControl]="scope.controls['description']" class="form-control-table" #input
                [ngClass]="{ 'is-invalid val-margin-top': (scope.controls.description.dirty || scope.controls.description.touched) && scope.controls.description.errors || scope.controls.description.untouched && validationMsg && scope.controls.description.invalid}">
                <div *ngIf="(scope.controls.description.dirty || scope.controls.description.touched) && scope.controls.description.errors || scope.controls.description.untouched && validationMsg && scope.controls.description.invalid" class="table-validation-msg text-left">
                  <div *ngIf="scope.controls.description.errors.maxlength">Should not be more than 1000 characters</div>
                </div>
              </span>
              <span *ngIf="editId!==i || !showSaveScope" title="{{ scope.get('description').value }}">{{ scope.get('description').value | slice:0:20}}</span>
            </td>
            <td>
              <div class="checkbox-aligncenter" *ngIf="editId==i && showSaveScope">
                <input class="styled-checkbox" id="saverequired{{i}}" type="checkbox" [formControl]="scope.controls['required']">
                <label for="saverequired{{i}}"></label>
              </div>
              <div class="checkbox-aligncenter" *ngIf="editId!==i || !showSaveScope">
                <input class="styled-checkbox" id="notsaverequired{{i}}" type="checkbox" [formControl]="scope.controls['required']" [attr.disabled]="true">
                <label for="notsaverequired{{i}}"></label>
              </div>
            </td>
            <td class="text-center">
              <div class="checkbox-aligncenter" *ngIf="editId==i && showSaveScope">
                <input class="styled-checkbox" id="saveemphasize{{i}}" type="checkbox" [formControl]="scope.controls['emphasize']">
                <label for="saveemphasize{{i}}"></label>
              </div>
              <div class="checkbox-aligncenter" *ngIf="editId!==i || !showSaveScope">
                <input class="styled-checkbox" id="scopeemphasize{{i}}" type="checkbox" [formControl]="scope.controls['emphasize']" [attr.disabled]="true">
                <label for="scopeemphasize{{i}}"></label>
              </div>
            </td>
            <td class="text-center">
              <div class="checkbox-aligncenter" *ngIf="editId==i && showSaveScope">
                <input class="styled-checkbox" id="saveshowInDiscoveryDocument{{i}}" type="checkbox" [formControl]="scope.controls['showInDiscoveryDocument']">
                <label for="saveshowInDiscoveryDocument{{i}}"></label>
              </div>
              <div class="checkbox-aligncenter" *ngIf="editId!==i || !showSaveScope">
                <input class="styled-checkbox" id="scopeshowInDiscoveryDocument{{i}}" type="checkbox" [formControl]="scope.controls['showInDiscoveryDocument']"
                  [attr.disabled]="true">
                <label for="scopeshowInDiscoveryDocument{{i}}"></label>
              </div>
            </td>
            <td class="text-center">
              <div class="btn-group" *ngIf="editId===i; else editCors">
                <div class="btn-group" *ngIf="!showSaveScope; else editCors1">
                  <button class="btn custom-btn" type="button" (click)="toggle(i, 'scopes')"><img src="../../../../assets/images/edit.svg" class="Combined-Shape"></button>
                  <button class="btn custom-btn" type="button" (click)="confirmModal(content, i, 'scopes', 'deleteApiScopeResourceIds')"><img src="../../../../assets/images/delete.svg" class="Combined-Shape delete-opacity"></button>
                </div>
                <ng-template #editCors1>
                  <div class="btn-group">
                    <button type="button" class="btn custom-btn" (click)="update()"><img src="../../../../assets/images/checked.svg" class="Combined-Shape"></button>
                    <button class="btn custom-btn" type="button" (click)="resetRedirectUri('scopes')"><img src="../../../../assets/images/cancel.svg" class="Combined-Shape"></button>
                  </div>
                </ng-template>
              </div>
              <ng-template #editCors>
                <div class="btn-group">
                    <button class="btn custom-btn" type="button" (click)="toggle(i, 'scopes')"><img src="../../../../assets/images/edit.svg" class="Combined-Shape"></button>
                    <button class="btn custom-btn" type="button"  (click)="confirmModal(content, i, 'scopes', 'deleteApiScopeResourceIds')"><img src="../../../../assets/images/delete.svg" class="Combined-Shape delete-opacity"></button>
                </div>
              </ng-template>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>

<div class="row mt-5" *ngIf="form">
  <div class=" col-sm-6 mb-2">
    <label>Secret</label>
  </div>
  <div class=" col-sm-6 text-right mb-2">
    <button type="button" class="btn-custom-secondary btn-label-secondary" (click)="addItem('apiSecrets')">Add secret</button>
  </div>
  <div class="table-responsive table-wraper">
    <table class="table table-hover">
      <thead>
        <tr>
          <th>Id</th>
          <th class="w-25">Expiration</th>
          <th class="w-25">Description</th>
          <th class="w-25">Value</th>
          <th class="w-25">Type</th>
          <th class="text-center">Actions</th>
        </tr>
      </thead>
      <tbody [formGroup]="form">
        <ng-container formArrayName="apiSecrets" *ngFor="let apiSecret of form.get('apiSecrets').controls; let i = index;">
          <tr *ngIf=" apiSecret.get('deleteOperation').value ">
            <td>
              <span title="{{ apiSecret.get('id').value }}">{{ apiSecret.get('id').value}}</span>
            </td>
            <td>
              <div *ngIf="editId==i && showSaveApiSecret">
                <div class="form-row">
                      <input class="form-control col-8" placeholder="YYYY-MM-DD"
                        name="d2" ngbDatepicker #d2="ngbDatepicker" [formControl]="apiSecret.controls['expiration']">
                        <button class="btn btn-outline-secondary btn-line-height col-3" (click)="d2.toggle()" type="button"><i class="fa fa-calendar" aria-hidden="true"></i></button>
                  </div>
              </div>
              <span *ngIf="editId!==i || !showSaveApiSecret" title="{{ apiSecret.get('expiration').value }}">{{ apiSecret.get('expiration').value | date: 'yyyy-MM-dd'}}</span>
            </td>
            <td>
              <span *ngIf="editId==i && showSaveApiSecret">
                <input type="text" [formControl]="apiSecret.controls['description']" class="form-control-table" #input
                [ngClass]="{ 'is-invalid val-margin-top': (apiSecret.controls.description.dirty || apiSecret.controls.description.touched) && apiSecret.controls.description.errors || apiSecret.controls.description.untouched && validationMsg && apiSecret.controls.description.invalid}">
                <div *ngIf="(apiSecret.controls.description.dirty || apiSecret.controls.description.touched) && apiSecret.controls.description.errors || apiSecret.controls.description.untouched && validationMsg && apiSecret.controls.description.invalid" class="table-validation-msg text-left">
                  <div *ngIf="apiSecret.controls.description.errors.maxlength">Should not be more than 1000 characters</div>
                </div>
              </span>
              <span *ngIf="editId!==i || !showSaveApiSecret" title="{{ apiSecret.get('description').value }}">{{ apiSecret.get('description').value | slice:0:20}}</span>
            </td>
            <td>
              <span *ngIf="editId!==i || !showSaveApiSecret" title="{{ apiSecret.get('value').value }}">{{ apiSecret.get('value').value | slice:0:20}}</span>
              <div *ngIf="editId==i && showSaveApiSecret">
                <input type="text" [formControl]="apiSecret.controls['value']" class="form-control-table d-inline width-size" title="{{ apiSecret.get('value').value }}"
                  readonly #input value="{{apiSecret.get('value').value }}"
                  [ngClass]="{ 'is-invalid val-margin-top': (apiSecret.controls.value.dirty || apiSecret.controls.value.touched) && apiSecret.controls.value.errors || apiSecret.controls.value.untouched && validationMsg && apiSecret.controls.value.invalid}">
                <button type="button" (click)="updateHashValue(i)" class="d-inline btn btn-outline-secondary btn-line-height">
                  <i class="fa fa-refresh" aria-hidden="true"></i>
                </button>
              </div>
              <div *ngIf="(apiSecret.controls.value.dirty || apiSecret.controls.value.touched) && apiSecret.controls.value.errors || apiSecret.controls.value.untouched && validationMsg && apiSecret.controls.value.invalid" class="table-validation-msg text-left">
                <div *ngIf="apiSecret.controls.value.errors?.required">Value is required</div>
                <div *ngIf="apiSecret.controls.value.errors?.maxlength">Should not be more than 75 characters</div>
              </div>
              <div *ngIf="editId==i && showSaveApiSecret && isHide">
                <input type="text" class="form-control-table d-inline width-size" readonly value="{{hashValue}}">
                <button type="button" (click)="isHide=false;" class="d-inline btn btn-outline-secondary btn-line-height">
                  <i class="fa fa-check" aria-hidden="true"></i>
                </button>
              </div>
            </td>
            <td>
              <span *ngIf="editId==i && showSaveApiSecret">
                <select id="country" [formControl]="apiSecret.controls['type']" [ngClass]="{ 'is-invalid val-margin-top': (apiSecret.controls.type.dirty || apiSecret.controls.type.touched) && apiSecret.controls.type.errors || apiSecret.controls.type.untouched && validationMsg && apiSecret.controls.type.invalid}"
                  class="dropdown-control">
                  <option value="SharedSecret">SharedSecret</option>
                  <option value="X509Thumbprint">X509Thumbprint</option>
                  <option value="X509Name">X509Name</option>
                  <option value="X509CertificateBase64">X509CertificateBase64</option>
                </select>
                <div *ngIf="(apiSecret.controls.type.dirty || apiSecret.controls.type.touched) && apiSecret.controls.type.errors || apiSecret.controls.type.untouched && validationMsg && apiSecret.controls.type.invalid" class="table-validation-msg text-left">
                  <div *ngIf="apiSecret.controls.type.errors?.required">Type is required</div>
                  <div *ngIf="apiSecret.controls.type.errors?.maxlength">Should not be more than 75 characters</div>
                </div>
              </span>
              <span *ngIf="editId!==i || !showSaveApiSecret" title="{{ apiSecret.get('type').value }}">{{ apiSecret.get('type').value | slice:0:10}}</span>
            </td>
            <td class="text-center">
              <div class="btn-group" *ngIf="editId===i; else editCors">
                <div class="btn-group" *ngIf="!showSaveApiSecret; else editCors1">
                  <button class="btn custom-btn" type="button" (click)="toggle(i, 'apiSecrets')"><img src="../../../../assets/images/edit.svg" class="Combined-Shape"></button>
                  <button class="btn custom-btn" type="button" (click)="confirmModal(content, i, 'apiSecrets', 'deleteApiSecretResourceIds')"><img src="../../../../assets/images/delete.svg" class="Combined-Shape delete-opacity"></button>
                </div>
                <ng-template #editCors1>
                  <div class="btn-group">
                    <button type="button" class="btn custom-btn" (click)="update()"><img src="../../../../assets/images/checked.svg" class="Combined-Shape"></button>
                    <button class="btn custom-btn" type="button" (click)="resetRedirectUri('apiSecrets')"><img src="../../../../assets/images/cancel.svg" class="Combined-Shape"></button>
                  </div>
                </ng-template>
              </div>
              <ng-template #editCors>
                <div class="btn-group">
                  <button class="btn custom-btn" type="button" (click)="toggle(i, 'apiSecrets')"><img src="../../../../assets/images/edit.svg" class="Combined-Shape"></button>
                  <button class="btn custom-btn" type="button" (click)="confirmModal(content, i, 'apiSecrets', 'deleteApiSecretResourceIds')"><img src="../../../../assets/images/delete.svg" class="Combined-Shape delete-opacity"></button>
                </div>
              </ng-template>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>

<ng-template #content let-close="close" let-dismiss="dismiss">
  <div class="modal-header">
    <span class="delete-icon-back"><img src="../../../../assets/images/delete.svg" class="delete-shape delete-opacity"></span><span class="modal-title">Delete Item</span>
    <button type="button" class="close" aria-label="Close" (click)="close('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
      <p>Are you sure you want to delete this item?</p>
  </div>
  <div class="modal-footer">
    <div class="actions btn-toolbar mt-3 justify-content-end">
      <div class="mr-3">
        <div (click)="delete()" class="btn-wrapper">
          <button class="btn-custom-primary-modal" type="button" [disabled]="submitting"><label class="btn-lable-primary-modal">{{ 'FE_General_Button_Ok' | translate }}</label></button>
        </div>
      </div>
      <div (click)="close('Close click')" class="btn-wrapper">
        <button class="btn-custom-secondary-modal" type="button"><label class="btn-lable-secondary-modal">{{ 'FE_General_Button_Cancel' | translate }}</label></button>
      </div>
    </div>
  </div>
</ng-template>