import { Injectable } from '@angular/core';
import { DataService } from '@core/services/DataService';
import { ILink, ICollectionModel, IServerCollectionResponse, IServerResponse } from '@core/core.interfaces';
import { IProduct } from '@modules/Product/product.model';
import { Endpoints } from '@config/endpoints';
import { find } from 'lodash';
import { ICustomer } from '@modules/Customer/customer.model';

@Injectable()
export class ProductService {

  public endpoints: ILink[];
  constructor(private dataService: DataService) { }

  async getAll(pageNumber: number, searchText: string = '', id: string = ''): Promise<ICollectionModel<IProduct>> {
      const data = await this.dataService.request<IServerCollectionResponse<IProduct>>(Endpoints.GetProducts, {
          query: {
              pageSize: 10,
              pageNumber: pageNumber,
              name: searchText,
              id: id,
          },
      });
      this.endpoints = data.result.links;
      return {
          records: data.result.records,
          pageInfo: data.pageInfo,
      };
  }

  async getById(id: string): Promise<IProduct> {
      const { records } = await this.getAll(1, '', id);
      const product = find(records, { id });
      if (!product) {
          throw new Error('Product not found');
      }
      return product;
  }

  async create(product: IProduct) {
    const data = await this.dataService.request<IServerResponse<IProduct>>(Endpoints.CreateProduct, {data: product});
    return data;
  }

  async update(product: IProduct) {
    await this.dataService.request<IServerResponse<IProduct>>(Endpoints.UpdateProduct, {
      links: product.links,
      data: product,
    });
  }

  async updateLogoImage(model: FormData, productId: string, pageNumber: number) {
    const { records } = await this.getAll(pageNumber);
    const product = find(records, { id: productId });
    if (!product) {
      throw new Error('Product not found');
    }
    const result = await this.dataService.request(Endpoints.UpdateProductLogo, {
        links: product.links,
        data: model,
    });
    return result;
  }

  isAttached(id: string, client: ICustomer)  {
    return !!find(client.products, { id });
  }

  async getLogo(customer: ICustomer) {
    const data = await this.dataService.request<IServerResponse<ICustomer>>(Endpoints.getProductLogo, {
      links: customer.links,
    });
    return data.result;
  }
}
