import { Injectable } from '@angular/core';
import { IEntityMapper } from '@core/core.interfaces';
import { IUserResponse, User, IUser } from '@modules/Users/user.model';

@Injectable()
export class UserMapper implements IEntityMapper<IUserResponse, User> {

  deserialize(response: IUserResponse): User {
    const model: IUser = {
      id: response.id,
      username: response.username,
      firstName: response.firstName,
      lastName: response.lastName,
      lastLogin: response.lastLogin,
      links: response.links,
      isActive: response.isActive,
      email: response.email,
      phone: response.phone,
      phoneNumber: response.phoneNumber,
      changePasswordOnNextLogin: response.changePasswordOnNextLogin,
      lockoutEnd: response.lockoutEnd,
      lockoutEnabled: response.lockoutEnabled,
      emailConfirmed: response.emailConfirmed,
      roles: response.roles,
      userProduct: response.userProduct,
      loginType: response.loginType,
      enableMFA: response.enableMFA,
    };
    return new User(model);
  }

  deserializeMany(response: IUserResponse[]): User[] {
    return response.map((item: IUserResponse) => this.deserialize(item));
  }

  serialize(model: User): IUserResponse {
    return {
      id: model.id,
      username: model.username,
      firstName: model.firstName,
      lastName: model.lastName,
      isActive: model.isActive,
      lastLogin: model.lastLogin,
      email: model.email,
      phone: model.phone,
      phoneNumber: model.phoneNumber,
      changePasswordOnNextLogin: model.changePasswordOnNextLogin,
      lockoutEnd: model.lockoutEnd,
      lockoutEnabled: model.lockoutEnabled,
      emailConfirmed: model.emailConfirmed,
      roles: model.roles,
      userProduct: model.userProduct,
      loginType: model.loginType,
      enableMFA: model.enableMFA,
    };
  }

}
