import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CoreModule } from '@core/core.module';
import { IdentityResourceService } from './services/IdentityResourceService';
import { IdentityResourcesListComponent } from './component/identity-resources-list/identity-resources-list.component';
import { EditFormIdentityResourcesComponent } from './component/edit-form-identity-resources/edit-form-identity-resources.component';
import { IdentityResourceFormBuilder } from '@modules/IdentityServer/services/IdentityResourceFormBuilder';
import { IdentityServerClientListComponent } from './component/identity-server-client-list/identity-server-client-list.component';
import {
  EditFormIdentityServerClientComponent,
} from './component/edit-form-identity-server-client/edit-form-identity-server-client.component';
import { IdentityClientService } from '@modules/IdentityServer/services/IdentityClientService';
import { IdentityClientFormBuilder } from '@modules/IdentityServer/services/IdentityClientFormBuildur';
import { DataService } from '@core/services/DataService';
import { ApiResourcesListComponent } from './component/api-resources-list/api-resources-list.component';
import { ApiResourceService } from '@modules/IdentityServer/services/ApiResourceService';
import { ApiResourceEditComponent } from './component/api-resource-edit/api-resource-edit.component';
import { ApiResourceFormBuilder } from '@modules/IdentityServer/services/ApiResourceFormBuilder';
import { IdentityClientsFormUrlsComponent } from './component/identity-clients-form-urls/identity-clients-form-urls.component';
import {
  IdentityClientsFormScopesSecretsComponent,
} from './component/identity-clients-form-scopes-secrets/identity-clients-form-scopes-secrets.component';
import { IdentityClientsFormOthersComponent } from './component/identity-clients-form-others/identity-clients-form-others.component';
import { ComponentsModule } from 'src/components/components.module';


@NgModule({
  imports: [
    CoreModule,
    FormsModule,
    ComponentsModule,
  ],
  providers: [
    IdentityResourceService,
    IdentityResourceFormBuilder,
    IdentityClientService,
    IdentityClientFormBuilder,
    ApiResourceService,
    DataService,
    ApiResourceFormBuilder,
  ],
  declarations: [
    IdentityResourcesListComponent,
    EditFormIdentityResourcesComponent,
    IdentityServerClientListComponent,
    EditFormIdentityServerClientComponent,
    ApiResourcesListComponent,
    ApiResourceEditComponent,
    IdentityClientsFormUrlsComponent,
    IdentityClientsFormScopesSecretsComponent,
    IdentityClientsFormOthersComponent,
  ],
  exports: [
    IdentityResourcesListComponent,
    EditFormIdentityResourcesComponent,
    IdentityServerClientListComponent,
    EditFormIdentityServerClientComponent,
    ApiResourcesListComponent,
    ApiResourceEditComponent,
    IdentityClientsFormScopesSecretsComponent,
    IdentityClientsFormOthersComponent,
    IdentityClientsFormUrlsComponent,
  ],
})
export class IdentityServerModule { }
