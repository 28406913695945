import { Injectable } from '@angular/core';
import { StatusService } from '@core/services/StatusService';

@Injectable()
export class ExcepionHandlingService {
  constructor(private status: StatusService) { }

  errorDisplay(error: any) {
    if (error.status === 409) {
      return error;
    } else if (error.status === 422) {
      error.error.Message = JSON.parse(error.error.Message);
      return error;
    } else if (error.status === 400 || error.status === 500) {
      this.status.httpError(error, { autoclose: true });
      return null;
    }
  }
}
