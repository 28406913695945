import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IIdentityResourceResponse } from '@modules/IdentityServer/identityServer.model';
import { IdentityResourceService } from '@modules/IdentityServer/services/IdentityResourceService';
import { ExcepionHandlingService } from '@core/services/ExceptionHandlingService';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'sf-app-identityresources-list',
  templateUrl: './identity-resources-list.component.html',
  styleUrls: ['./identity-resources-list.component.scss'],
})
export class IdentityResourcesListComponent implements OnInit {
  @Input() clients: IIdentityResourceResponse[];
  @Input() currentPageNumber: number;
  public loading: boolean;
  @Input() error: any;
  @Output() update = new EventEmitter<void>();
  public resource: IIdentityResourceResponse;
  public modalReference: NgbModalRef;

  constructor(
    private service: IdentityResourceService,
    private exception: ExcepionHandlingService,
    private modalService: NgbModal,
  ) { }

  ngOnInit() {
  }

  confirmModal(content: any, resource: IIdentityResourceResponse) {
    this.resource = resource;
    this.modalReference = this.modalService.open(content, { centered: true });
  }

  async delete() {
    try {
      await this.service.delete(this.resource);
      this.update.emit();
    } catch (error) {
      this.error = this.exception.errorDisplay(error);
    } finally {
      this.modalReference.close();
     }
  }
}
