<div class="form-group row">
  <label for="code" class="control col-sm-3 col-md-2 col-form-label text-field-label">{{ 'FE_General_Lable_Code' | translate }}<span class="mandetory-input ml-1">*</span></label>
  <input id="code" class="form-control col-sm-5" [formControl]="form.controls['code']" (keyup)="error=null"
   [ngClass]="{ 'is-invalid': ((controls.code.dirty || controls.code.touched) && controls.code.errors || controls.code.untouched && showMsg && controls.code.invalid)  || error!==null && ( error.status===409 ||  (error.status===422 && error.error.Message.Name))}">
  <div *ngIf="(controls.code.dirty || controls.code.touched) && controls.code.errors || controls.code.untouched && showMsg && controls.code.invalid" class="invalid-feedback validation-msg">
    <div *ngIf="controls.code.errors?.required">Code is required</div>
    <div *ngIf="controls.code.errors?.pattern">Number only</div>
    <div *ngIf="controls.code.errors?.maxlength && !controls.code.errors?.pattern">Code 5 digits only</div>
  </div>
  <div *ngIf="error!==null && error.status===409" class="invalid-feedback display-block validation-msg">Code already exists</div>
  <div *ngIf="error!==null && error.status===422" class="invalid-feedback display-block validation-msg">{{error.error.Message.Code}}</div>
</div>
<div class="form-group row">
  <label for="name" class="control col-sm-3 col-md-2 col-form-label text-field-label">{{ 'FE_General_Lable_Name' | translate }}<span class="mandetory-input ml-1">*</span></label>
  <input id="name" class="form-control col-sm-5" [formControl]="form.controls['name']" (keyup)="error=null"
  [ngClass]="{ 'is-invalid': ((controls.name.dirty || controls.name.touched) && controls.name.errors || controls.name.untouched && showMsg && controls.name.invalid) || error!==null && (error.status===422 && error.error.Message.Name)}">
  <div *ngIf="(controls.name.dirty || controls.name.touched) && controls.name.errors || controls.name.untouched && showMsg && controls.name.invalid" class="invalid-feedback validation-msg">
    <div *ngIf="controls.name.errors?.required">Name is required</div> 
    <div *ngIf="controls.name.errors?.pattern">'-' and '_' special characters allowed only</div>
    <div *ngIf="controls.name.errors?.maxlength && !controls.name.errors.pattern">Should not be more than 75 characters</div>
  </div>
  <div *ngIf="error!==null && error.status===422" class="invalid-feedback display-block validation-msg">{{error.error.Message.Name}}</div>
</div>
<div class="form-group row">
  <label for="description" class="control col-sm-3 col-md-2 col-form-label text-field-label">{{ 'FE_General_Lable_Description' | translate }}</label>
  <textarea id="description" class="form-control col-sm-5" [formControl]="form.controls['description']" [ngClass]="{ 'is-invalid': (controls.description.dirty || controls.description.touched) && controls.description.errors || controls.description.untouched && showMsg && controls.description.invalid}"></textarea>
  <div *ngIf="(controls.description.dirty || controls.description.touched) && controls.description.errors || controls.description.untouched && showMsg && controls.description.invalid" class="validation-msg invalid-feedback">
    <div *ngIf="controls.description.errors?.maxlength">Should not be more than 1000 characters</div>
  </div>
</div>
<div class="form-group row">
  <label for="imageLogo" class="control col-sm-3 col-md-2 col-form-lable text-field-label">{{ 'FE_General_Lable_Logo' | translate }}</label>
  <div class="col-sm-4 col-md-3 col-lg-2 col-6 row mx-0 pl-md-0" sfFileDrop (filesDropped)="handleFileInput($event)">
    <span class="preview-image" [ngClass]="disableLogoChange?'border':'disabledBorder'">
      <img [src]="imagePath || imageUrl" (click)="image.click()">
    </span>
  </div>
  <input hidden type="file" #image accept="image/*" id="logo" (change)="handleFileInput($event.target.files)" class="col-sm-4" [disabled]="!disableLogoChange">
  <div class="ml-3">
    <div (click)="upload()" class="btn-wrapper">
      <button class="btn-custom-secondary" type="button" [disabled]="!disableLogoChange"><label [ngClass]="!disableLogoChange ? 'disableLabel' : ''" class="btn-lable-secondary">{{ 'FE_General_Button_Upload' | translate }}</label></button>
    </div>  
  </div> 
</div>      
