import { DataService } from '@core/services/DataService';
import { Injectable } from '@angular/core';
import { IServerResponse, ILink, IServerCollectionResponse } from '@core/core.interfaces';
import { IIdentityResourceResponse } from '@modules/IdentityServer/identityServer.model';
import { find } from 'lodash';
import { Endpoints } from '@config/endpoints';

@Injectable()
export class IdentityResourceService {
  public endpoints: ILink[];

  constructor(private dataService: DataService) { }

  async getAllClients(pageNumber: number, searchText: string = '', id: number = null) {
    const data = await this.dataService.request<IServerCollectionResponse<IIdentityResourceResponse>>(Endpoints.GetIdentityResources, {
      query: {
        pageSize: 10,
        pageNumber: pageNumber,
        name: searchText,
        id: id,
      },
    });
    this.endpoints = data.result.links;
    return {
      records: data.result.records,
      pageInfo: data.pageInfo,
    };
  }

  async getClientInfo(id: number): Promise<IIdentityResourceResponse> {
    const { records } = await this.getAllClients(1, '', id);
    const client = find(records, { id });
    if (!client) {
      throw new Error('Client not found');
    }
    return client;
  }

  async updateIdentityResourceClient(client: IIdentityResourceResponse) {
    return this.dataService.request<IServerResponse<IIdentityResourceResponse>>(Endpoints.UpdateIdentityResources, {
      links: client.links,
      data: client,
    });
  }

  async createIdentityResource(client: IIdentityResourceResponse) {
    return this.dataService.request<IServerResponse<IIdentityResourceResponse>>(Endpoints.CreateIdentityResources, {
      data: client,
    });
  }

  async delete(client: IIdentityResourceResponse) {
    await this.dataService.request<IServerResponse<IIdentityResourceResponse>>(Endpoints.DeleteIdentityResource, {
      links: client.links,
    });
  }

}
