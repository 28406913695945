import { FormGroup } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ICustomer } from '@modules/Customer/customer.model';
import { CustomerService } from '@modules/Customer/services/CustomerService';
import { CustomerFormBuilder } from '@modules/Customer/services/CustomerFormBuilder';
import { ProductService } from '@modules/Product/services/ProductService';
import { IProduct } from '@modules/Product/product.model';
import { IPageInfoResponse } from '@core/core.interfaces';
import { UserService } from '@modules/Users/services/UserService';
import { IUser } from '@modules/Users/user.model';
import { NgbModal, NgbModalRef, NgbModalOptions, NgbTabChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { UserFormBuilder } from '@modules/Users/services/UserFormBuilder';
import { DomSanitizer } from '@angular/platform-browser';
import { ExcepionHandlingService } from '@core/services/ExceptionHandlingService';



@Component({
  templateUrl: 'customer-edit.page.html',
  styleUrls: ['customer-edit.page.scss'],
})
export class CustomerEditPageComponent implements OnInit {
  public loading = false;
  public submitting = false;
  public error: any = null;
  public customer: ICustomer;
  public formCustomer: FormGroup;
  public formUser: FormGroup;
  public products: IProduct[];
  public pageInfo: IPageInfoResponse;
  public collectionSize: number;
  public productCurrentPageNumber = 1;
  public productCollectionSize: number;
  public users: IUser[];
  public userPageInfo: IPageInfoResponse;
  public userCollectionSize: number;
  public userCurrentPageNumber = 1;
  public modalReference: NgbModalRef;
  public validateModalReference: NgbModalRef;
  public ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
    size: 'lg',
  };
  public searchText: string;
  public canCreate: boolean;

  public selectedTab: string;
  public imagePath: any;
  public tabRoutes: any;
  public totalPages: number;
  public showMsg = false;
  public totalProductPages: number;
  public totalUserPages: number;
  constructor(
    private customerService: CustomerService,
    private productService: ProductService,
    private customerformBuilder: CustomerFormBuilder,
    private userFormBuilder: UserFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private modalService: NgbModal,
    private sanitizer: DomSanitizer,
    private exception: ExcepionHandlingService,
  ) { }

  onTabChange($event?: NgbTabChangeEvent) {
    this.searchText = '';
    this.selectedTab = $event.nextId;
    if ($event.nextId === 'general') {
      this.loadCustomer();
    } else if ($event.nextId === 'products') {
      this.loadProducts(1);
    } else {
      this.loadUsers(1);
    }
    this.router.navigateByUrl(this.tabRoutes[$event.nextId]);
  }

  get customerId() {
    return this.route.snapshot.params.id;
  }

  resetValidationMsg() {
    this.showMsg = false;
  }

  ngOnInit() {
    this.tabRoutes = {
      general: '/customers/' + this.customerId + '/general',
      products: '/customers/' + this.customerId + '/products',
      users: '/customers/' + this.customerId + '/users',
    };
    this.selectedTab = this.route.snapshot.params.tab;
    this.loadCustomer();
  }

  loadingToggle() {
    this.loading = !this.loading;
  }

  async loadCustomer() {
    try {
      this.loading = true;
      this.error = null;
      const customer = await this.customerService.getById(this.customerId);
      const form = this.customerformBuilder.fromModel(customer);
      this.customer = customer;
      this.formCustomer = form;
      if (this.selectedTab === 'products') {
        this.loadProducts(1);
      } else if (this.selectedTab === 'users') {
        this.loadUsers(1);
      } else if (this.selectedTab === 'general') {
        this.getLogo(this.customer);
      }
    } catch (err) {
      this.error = this.exception.errorDisplay(err);
    } finally {

    }
  }

  async getLogo(customer: any) {
    try {
      const logo = await this.customerService.getLogo(customer);
      if (logo.logoImage) {
        this.imagePath = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + logo.logoImage);
      }
    } catch (err) {
      this.error = this.exception.errorDisplay(err);
     }
    finally {
      this.loading = false;
    }
  }

  async onSubmit() {
    if (this.formCustomer.invalid) {
      return;
    }

    const customer = this.customerformBuilder.map(this.formCustomer, this.customer);
    try {
      this.submitting = true;
      await this.customerService.update(customer);
      this.router.navigate(['customers']);
    } catch (err) {
      this.error = this.exception.errorDisplay(err);
    } finally {
      this.submitting = false;
    }
  }

  searchUsers(search: string) {
    this.searchText = search;
    this.loadUsers(1);
  }

  searchProducts(search: string) {
    this.searchText = search;
    this.loadProducts(1);
  }

  async loadProducts(productCurrentPageNumber: number) {
    try {
      this.products = [];
      this.totalProductPages = 1;
      this.loading = true;
      const result = await this.productService.getAll(productCurrentPageNumber, this.searchText);
      this.products = result.records;
      this.pageInfo = result.pageInfo;
      this.productCollectionSize = this.pageInfo.totalRecords;
      this.totalProductPages = this.pageInfo.totalPages;
    } catch (err) {
      this.error = this.exception.errorDisplay(err);
    } finally {
      this.loading = false;
    }
  }

  async loadUsers(userCurrentPageNumber: number) {
    try {
      this.users = [];
      this.totalUserPages = 1;
      this.loading = true;
      this.error = null;
      const result = await this.userService.getAll(this.customer, userCurrentPageNumber, this.searchText);
      this.users = result.records;
      this.userPageInfo = result.pageInfo;
      this.userCollectionSize = this.userPageInfo.totalRecords;
      this.totalUserPages = this.userPageInfo.totalPages;
    } catch (err) {
      this.error = this.exception.errorDisplay(err);
    } finally {
      this.loading = false;
    }
  }

  editModel(content: any) {
    this.modalReference = this.modalService.open(content, this.ngbModalOptions);
    this.createForm();
  }

  async createForm() {
    this.formUser = this.userFormBuilder.createEmpty();
  }

  async createUser() {
    if (this.formUser.invalid) {
      this.showMsg = true;
      return;
    }

    const user = this.userFormBuilder.toModel(this.formUser);
    try {
      this.error = null;
      this.submitting = true;
      if (this.validateModalReference !== undefined) {
        this.validateModalReference.close();
      }
      await this.userService.create(user);
      this.loadUsers(1);
    } catch (err) {
      this.error = this.exception.errorDisplay(err);
    } finally {
      this.submitting = false;
      this.formUser.reset();
      this.modalReference.close();
    }
  }

  async validateUser(content: any) {
    if (this.formUser.invalid) {
      this.showMsg = true;
      return;
    }

    const user = this.userFormBuilder.toModel(this.formUser);
    try {
      this.error = null;
      this.submitting = true;
      const result = await this.userService.validateUserByUserName(user);
      this.canCreate = result.canCreate;
      if (result.isExists) {
        this.validateModalReference = this.modalService.open(content, { centered: true });
      } else {
        this.createUser();
      }
    } catch (err) {
      this.error = this.exception.errorDisplay(err);
      this.validateModalReference.close();
    } finally {
      this.submitting = false;
    }

  }

}


