import { reduce } from 'lodash';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { UrlBuilder } from '@core/services/UrlBuilder';
import { IDataService, IDataRequest, ILink, IKeyValue } from '@core/core.interfaces';
import { StaticEndpoints, Endpoints } from '@config/endpoints';

@Injectable()
export class DataService implements IDataService {
  constructor(
    private urlBuilder: UrlBuilder,
    private http: HttpClient,
  ) { }

  get<T>(url: string, query?: {}): Promise<T> {
    const { path, params } = this.constructRequest(url, query);
    const subscription = this.http.get<T>(path, { params });
    return subscription.toPromise();
  }

  delete<T>(url: string, query?: {}): Promise<T> {
    const { path, params } = this.constructRequest(url, query);
    const subscription = this.http.delete<T>(path, { params });
    return subscription.toPromise();
  }

  post<T>(url: string, data?: {}, query?: {}): Promise<T> {
    const { path, params } = this.constructRequest(url, query);
    const subscription = this.http.post<T>(path, data, { params });
    return subscription.toPromise();
  }

  put<T>(url: string, data?: {}, query?: {}): Promise<T> {
    const { path, params } = this.constructRequest(url, query);
    const subscription = this.http.put<T>(path, data, { params });
    return subscription.toPromise();
  }

  request<T>(endpoint: Endpoints, req: IDataRequest = {}): Promise<T> {
    const link = (req && req.links) ?
      this.urlBuilder.getEndpoint(endpoint, req.links) :
      this.urlBuilder.findEndpoint(endpoint);

    const query = this.constructQuery(link.query, req.query);
    const stream = this.http.request<T>(link.method, link.href, {
      body: req && req.data,
      params: query,
      headers: req.headers,
    });

    return stream.toPromise();
  }

  async defineEndpoints() {
    const endpoints = await this.get<ILink[]>(StaticEndpoints.Root);
    this.urlBuilder.setEndpoints(endpoints);
    return endpoints;
  }

  getClient() {
    return this.http;
  }

  private constructRequest(url: string, query?: {}): IDataServiceRequestShape {
    const path = this.urlBuilder.build(url);
    let params;
    if (query) {
      params = this.constructQuery(query);
    }
    return { path, params };
  }

  private constructQuery(...params: IKeyValue[]) {
    const fromObject = reduce(params, (result: IKeyValue, current: IKeyValue): IKeyValue => {
      return { ...result, ...current };
    }, {});
    return new HttpParams({ fromObject });
  }

}

interface IDataServiceRequestShape {
  path: string;
  params: HttpParams;
}
