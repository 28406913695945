import { ApplicationPagesModule } from './pages/pages.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterConfigurationModule } from '@config/routing';
import { CoreModule } from '@core/core.module';
import { LoginLayoutComponent } from './layouts/login-layout/login-layout.component';
import { ApplicationLayoutComponent } from './layouts/application-layout/application-layout.component';
import { AccessManagerLayoutComponent } from './layouts/access-manager-layout/access-manager-layout.component';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { AuthInterceptor } from '@core/services/AuthInterceptor';
import { ComponentsModule } from '../components/components.module';
import { LogoutLayoutComponent } from './layouts/logout-layout/logout-layout.component';

@NgModule({
  declarations: [
    AppComponent,
    ApplicationLayoutComponent,
    LoginLayoutComponent,
    LogoutLayoutComponent,
    AccessManagerLayoutComponent,
  ],
  imports: [
    CoreModule,
    ApplicationPagesModule,
    RouterConfigurationModule,
    ComponentsModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
