import { Component, OnInit } from '@angular/core';
import { IPageInfoResponse } from '@core/core.interfaces';
import { ICustomer } from '@modules/Customer/customer.model';
import { CustomerService } from '@modules/Customer/services/CustomerService';
import { ExcepionHandlingService } from '@core/services/ExceptionHandlingService';

@Component({
  templateUrl: 'customers.page.html',
  styleUrls: ['customers.page.scss'],
})
export class CustomersPageComponent implements OnInit {

  public loading = false;
  public error: any = null;
  public customers: ICustomer[];
  public pageInfo: IPageInfoResponse;
  public pageNumbers: any;
  public currentPageNumber = 1;
  public collectionSize: number;
  public searchText: string;
  public totalPages: number;

  constructor(
    private service: CustomerService,
    private exception: ExcepionHandlingService,
  ) { }


  ngOnInit() {
    this.loadCustomers(1);
  }

  search(search: string) {
    this.searchText = search;
    this.loadCustomers(1);
  }

  async loadCustomers(pageNumber: number) {
    try {
      this.loading = true;
      const result = await this.service.getAll(pageNumber, this.searchText);
      this.customers = result.records;
      this.pageInfo = result.pageInfo;
      this.collectionSize = this.pageInfo.totalRecords;
      this.totalPages = this.pageInfo.totalPages;
    } catch (err) {
      this.error = this.exception.errorDisplay(err);
    } finally {
      this.loading = false;
    }
  }

}
