import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[sfFileDrop]',
})
export class FileDropDirective {

  @Output() filesDropped =  new EventEmitter<FileList>();

  constructor() { }

  @HostListener('drop', ['$event'])
    onDrop($event: any) {
      $event.preventDefault();

      const transfer = $event.dataTransfer;
      this.filesDropped.emit(transfer.files);
    }

    @HostListener('dragover', ['$event'])
     onDragOver($event: any) {
       $event.preventDefault();
     }


}
