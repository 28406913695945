<div class="col-md-6 offset-md-6 col-sm-12">
  <div class="search-box mt-3">
    <i class="fa fa-search fa-2x"></i>
    <input type="text" class="search" placeholder="{{ 'FE_General_Search_PlaceHolder' | translate }}" [(ngModel)]="searchVal">
  </div>
</div>
<div class="table-wraper table-responsive mt-3">
  <table class="table">
    <thead>
      <tr>
        <th>{{ 'FE_General_Lable_PermissionName' | translate }}</th>
        <th>{{ 'FE_General_Lable_Comment' | translate }}</th>
        <th>{{ 'FE_General_Lable_PermissionCode' | translate }}</th>
        <!-- Expanding table to the right with role names, adding columns -->
        <th *ngFor="let r of rolesResult.roles">{{r.name}} {{r.code}}</th>
      </tr>
    </thead>
    <tbody >
      <tr *ngFor="let p of rolesResult.permissionDetails | RolePermissionFilter: searchVal">
        <td>{{p.name}}</td>
        <td>{{p.comment}}</td>
        <td>{{p.code}}</td>
        <!-- Fetching permissions for each role -->
        <td *ngFor="let marked of p.rolesMarked">
          <div class="checkbox-aligncenter">
            <input class="styled-checkbox" id="roleCheckbox" type="checkbox" [ngModel]="marked" disabled>
            <label for="roleCheckbox"></label>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>




