import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { User, IUser, IProductClient } from '@modules/Users/user.model';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup } from '@angular/forms';
import { UserFormBuilder } from '@modules/Users/services/UserFormBuilder';
import { UserService } from '@modules/Users/services/UserService';
import { ICustomer } from '@modules/Customer/customer.model';

@Component({
  selector: 'sf-users-list',
  templateUrl: 'users-list.component.html',
  styleUrls: ['users-list.component.scss'],
})
export class UsersListComponent implements OnInit {
  @Input() users: User[];
  @Input() userCurrentPageNumber: number;
  @Input() customer: ICustomer;
  @Input() searchText: string;
  @Output() update = new EventEmitter<void>();
  public formUser: FormGroup;
  public modalReference: NgbModalRef;
  public error: any = null;
  public submitting = false;
  public loading = true;
  public ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
    size: 'lg',
  };
  public userId: string;
  public user: IUser;
  public showMsg = false;

  constructor(
    private service: UserService,
    private modalService: NgbModal,
    private formBuilder: UserFormBuilder,
  ) { }

  ngOnInit() {
  }

  filter(model: IProductClient[]) {
    return model.filter((userProduct: IProductClient) => userProduct.checked);
  }

  resetValidationMsg() {
    this.showMsg = false;
  }

  async editModel(content: any, userDetails: IUser) {
    try {
      const form = this.formBuilder.fromModel(userDetails);
      this.user = userDetails;
      this.formUser = form;
      this.userId = userDetails.id;
      this.modalReference = this.modalService.open(content, this.ngbModalOptions);
    } catch (err) {
      this.modalReference.close();
      this.error = err;
    } finally {
      this.loading = false;
    }
  }

  async updateUser() {
    if (this.formUser.invalid) {
      this.showMsg = true;
      return;
    }
    const user = this.formBuilder.map(this.formUser, this.user);
    try {
      this.submitting = true;
      await this.service.update(user);
      this.update.emit();
      this.modalReference.close();
    } catch (error) {
      this.error = error;
    } finally {
      this.submitting = false;
    }
  }

  confirmModal(content: any, user: User) {
    this.user = user;
    this.modalReference = this.modalService.open(content, { centered: true });
  }

  async deleteUser() {
    try {
      this.submitting = true;
      await this.service.delete(this.user);
      this.update.emit();
      this.modalReference.close();
    } catch (error) {
      this.error = error;
    } finally {
      this.submitting = false;
    }
  }

}
