<div *ngIf="clients && !clients.length" class="text-center">
  <strong>{{ 'FE_General_Label_NoResult' | translate }}</strong>
</div>
<div class="table-responsive table-wraper" [ngClass]="( clients && clients.length)?'table-visible':'table-hidden'">
  <table class="table table-hover">
    <thead>
      <tr>
        <th>{{ 'FE_General_Lable_Id' | translate }}</th>
        <th>{{ 'FE_General_Lable_Enabled' | translate }}</th>
        <th>{{ 'FE_General_Lable_Name' | translate }}</th>
        <th>{{ 'FE_General_Lable_DisplayName' | translate }}</th>
        <!-- <th>Description</th> -->
        <th>{{ 'FE_General_Lable_Required' | translate }}</th>
        <th>{{ 'FE_General_Lable_Emphasize' | translate }}</th>
        <th>{{ 'FE_General_Lable_ShowInDiscoveryDocuments' | translate }}</th>
        <th class="text-center">{{ 'FE_General_Lable_Actions' | translate }}</th>
      </tr>
    </thead>

    <tr *ngFor="let record of clients; let i=index">
      <td>{{record.id}}</td>
      <td>
        <div class="checkbox-aligncenter">
          <input class="styled-checkbox" id="customCheck0" type="checkbox" [checked]="record.enabled" disabled>
          <label for="customCheck0"></label>
        </div>
      </td>
      <td>{{record.name}}</td>
      <td>{{record.displayName}}</td>
      <!-- <td>{{record.description}}</td> -->
      <td>
        <div class="checkbox-aligncenter">
          <input class="styled-checkbox" id="customCheck0" type="checkbox" [checked]="record.required" disabled>
          <label for="customCheck0"></label>
        </div>
      </td>
      <td>
        <div class="checkbox-aligncenter">
          <input class="styled-checkbox" id="customCheck1" type="checkbox" [checked]="record.emphasize" disabled>
          <label for="customCheck1"></label>
        </div>
      </td>
      <td>
        <div class="checkbox-aligncenter">
          <input class="styled-checkbox" id="customCheck2" type="checkbox" [checked]="record.showInDiscoveryDocument" disabled>
          <label for="customCheck2"></label>
        </div>
      </td>
      <td class="text-center">
        <div class="btn-group">
          <button class="btn custom-btn" routerLink="/identity-resources/{{record.id}}">
            <img src="../../../../assets/images/edit.svg" class="Combined-Shape">
          </button>
          <span class="line-sperator"></span>
          <button class="btn custom-btn" (click)="confirmModal(content, record)">
            <img class="Combined-Shape delete-opacity" src="../../../../assets/images/delete.svg">
          </button>
        </div>
      </td>
    </tr>
  </table>
</div>

<ng-template #content let-close="close" let-dismiss="dismiss">
  <div class="modal-header">
    <span class="delete-icon-back"><img src="../../../../assets/images/delete.svg" class="delete-shape"></span><span class="modal-title">Delete Item</span>
    <button type="button" class="close" aria-label="Close" (click)="close('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p> Are you sure you want to delete this item?</p>
  </div>
  <div class="modal-footer">
    <div class="actions btn-toolbar mt-3 justify-content-end">
      <div class="mr-3">
        <div (click)="delete()" class="btn-wrapper">
          <button class="btn-custom-primary-modal" type="button" [disabled]="submitting"><label class="btn-lable-primary-modal">{{ 'FE_General_Button_Ok' | translate }}</label></button>
        </div>
      </div>
      <div (click)="close('Close click')" class="btn-wrapper">
        <button class="btn-custom-secondary-modal" type="button"><label class="btn-lable-secondary-modal">{{ 'FE_General_Button_Cancel' | translate }}</label></button>
      </div>
    </div>
  </div>
</ng-template>