import { Component, OnInit, ViewChild } from '@angular/core';
import { IApiResource } from '@modules/IdentityServer/identityServer.model';
import { FormGroup } from '@angular/forms';
import { ApiResourceFormBuilder } from '@modules/IdentityServer/services/ApiResourceFormBuilder';
import { ApiResourceService } from '@modules/IdentityServer/services/ApiResourceService';
import { Router } from '@angular/router';
import { ApiResourceEditComponent } from '@modules/IdentityServer/component/api-resource-edit/api-resource-edit.component';
import { ExcepionHandlingService } from '@core/services/ExceptionHandlingService';

@Component({
  selector: 'sf-api-resource-create',
  templateUrl: './api-resource-create.component.html',
  styleUrls: ['./api-resource-create.component.scss'],
})
export class ApiResourceCreatePageComponent implements OnInit {
  public submitting = false;
  public error: any = null;
  public form: FormGroup;
  public resource: IApiResource;
  public showMsg = false;
  @ViewChild(ApiResourceEditComponent) formComponent: ApiResourceEditComponent;

  constructor(
    private router: Router,
    private formBuilder: ApiResourceFormBuilder,
    private service: ApiResourceService,
    private exception: ExcepionHandlingService,
  ) { }

  ngOnInit() {
    this.createForm();
  }

  async createForm() {
    this.form = this.formBuilder.createEmpty();
  }

  async onSubmit() {
    this.formComponent.removeItem();
    if (this.form.invalid) {
      this.showMsg = true;
      return;
    }

    const resource = this.formBuilder.toModel(this.form);
    try {
      this.error = null;
      this.submitting = true;
      await this.service.create(resource);
      this.router.navigate(['identity/api-resources']);
    } catch (error) {
      this.error = this.exception.errorDisplay(error);
    } finally {
      this.submitting = false;
    }
  }
}
