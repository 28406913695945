import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { APP_CONFIG } from '@config/di-tokens';
import { config } from '../environments/environment';
import { UrlBuilder } from '@core/services/UrlBuilder';
import { DataService } from '@core/services/DataService';
import { SessionService } from '@core/services/Session';
import { AuthService } from '@core/services/AuthService';
import { AuthGuard } from '@core/services/AuthGuard';
import { RolePermissionFilterPipe } from '@modules/Roles/pipes/role-permission-filter.pipe';
import { DebounceDirective } from 'src/directives/debounce.directive';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslationLoader } from '@core/services/TranslationLoader';
import { TranslationService } from '@core/services/TranslateService';
import { FileDropDirective } from '../directives/file-drop.directive';
import { StatusService } from './services/StatusService';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExcepionHandlingService } from '@core/services/ExceptionHandlingService';

@NgModule({
  declarations: [
    RolePermissionFilterPipe,
    DebounceDirective,
    FileDropDirective,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    HttpClientModule,
    BrowserAnimationsModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: function createTranslateLoader(dataService: DataService) {
          return new TranslationLoader(dataService);
        },
        deps: [DataService],
      },
    }),
  ],
  providers: [
    { provide: APP_CONFIG, useValue: config },
    UrlBuilder,
    DataService,
    SessionService,
    AuthService,
    AuthGuard,
    TranslationService,
    StatusService,
    ExcepionHandlingService,
  ],
  exports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule,
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    RolePermissionFilterPipe,
    DebounceDirective,
    TranslateModule,
    FileDropDirective,
  ],
})
export class CoreModule { }
