import { Pipe, PipeTransform } from '@angular/core';
import { StringHelper } from '@core/utils/StringHelper';
import { IPermissionDetail } from '@modules/Roles/role.model';

@Pipe({ name: 'RolePermissionFilter' })
export class RolePermissionFilterPipe implements PipeTransform {
  transform(roles: IPermissionDetail[], searchText: string) {
    if (!roles || !searchText) {
      return roles;
    }
    return StringHelper.search(roles, searchText);
  }
}
