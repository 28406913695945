<div class="mt-3">
  <div class="">
    <input class="form-checkbox" id="customCheck0" type="checkbox" [formControl]="formUser.controls['changePasswordOnNextLogin']">
    <label for="customCheck0">{{ 'FE_General_Lable_ChangePasswordOnNextLogin' | translate }}</label>
  </div>
  <div>
    <input class="form-checkbox" id="checkIsacive" type="checkbox" [formControl]="formUser.controls['isActive']">
    <label for="checkIsacive">{{ 'FE_General_Lable_IsActive' | translate }}</label>
  </div>
  <div>
    <input class="form-checkbox" id="checkEnableMFA" type="checkbox" [formControl]="formUser.controls['enableMFA']">
    <label for="checkEnableMFA">{{ 'Enable MFA' | translate }}</label>
  </div>
  <div>
      <input class="form-checkbox" id="checkLockout" type="checkbox" [formControl]="formUser.controls['lockoutEnabled']">
      <label for="checkLockout">{{ 'FE_General_Lable_LockoutEnabled' | translate }}</label>
    </div>
  <hr>
  <div class="form-group row">
    <label for="passChange" class="control col-sm-4 col-form-label text-field-label">{{ 'FE_General_Lable_LastPasswordChange' | translate }}</label>
    <input id="passChange" class="form-control col-sm-5 mr-2" [attr.disabled]="true">
  </div>
  <div class="form-group row">
    <label class="control col-sm-4 col-form-label"></label>
    <input type="password" class="col-sm-5 form-control" [(ngModel)]="password">
    <button (click)="setPassword()" class="col-sm-3 btn btn-secondary" [disabled]="isSetPasswordEnabled">{{ 'FE_General_Button_SetNewPassword' | translate }}</button>
  </div>
  <div class="form-group row">
    <label class="control col-sm-4 col-form-label"></label>
    <button class="col-sm-5 btn btn-secondary" (click)="resetPassword()" [disabled]="isSendResetPasswordEnabled">{{ 'FE_General_Button_Sendresetpasswordlink' | translate }}</button>
  </div>
  <div class="form-group row">
    <label for="lastLogin" class="control col-sm-4 col-form-label text-field-label">{{ 'FE_General_Lable_LastLogin' | translate }}</label>
    <input type="datetime-local" id="lastLogin" class="form-control col-sm-5 mr-2" [(ngModel)]="formUser.get('lastLogin').value"
    [attr.disabled]="true" *ngIf="isValid(formUser.get('lastLogin').value); else showValidLog;">
    <ng-template #showValidLog>
      <input id="lastLogin" class="form-control col-sm-5 mr-2" [attr.disabled]="true" value="No Value">
    </ng-template>
  </div>
  <div class="form-group row">
    <label for="lockoutExpiry" class="control col-sm-4 col-form-label text-field-label">{{ 'FE_General_Lable_LockoutExpires' | translate }}</label>
    <input type="datetime-local" id="lockoutExpiry" class="form-control col-sm-5 mr-2" [attr.disabled]="true" [(ngModel)]="formUser.get('lockoutEnd').value"
      *ngIf="isValid(formUser.get('lockoutEnd').value); else showValidLoc;">
    <ng-template #showValidLoc>
      <input id="lockoutExpiry" class="form-control col-sm-5 mr-2" [attr.disabled]="true" value="No Value">
    </ng-template>
  </div>
</div>