<div class="page section-bg">
  <h1 *ngIf="client" class="mb-3">{{ 'FE_General_Lable_Edit' | translate }} {{client.clientName}}</h1>
  <sf-app-loading [loading]="loading"></sf-app-loading>
  <form *ngIf="form" [formGroup]="form" (submit)="onSubmit()" class="mt-3 col-md-12">
    <sf-app-edit-form-identityserverclient [form]="form" [client]="client" [showFields]="true" [error]="error"></sf-app-edit-form-identityserverclient>
    <div class="actions btn-toolbar mt-3 justify-content-end">
      <div class="mr-3">
        <div (click)="onSubmit()" class="btn-wrapper"><button class="btn-custom-primary" type="submit" [disabled]="submitting"><label class="btn-lable-primary">{{ 'FE_General_Button_Save' | translate }}</label></button></div>
      </div>
      <div routerLink="/identity-clients" class="btn-wrapper"><button class="btn-custom-secondary" type="button"><label class="btn-lable-secondary">{{ 'FE_General_Button_Cancel' | translate }}</label></button></div>
    </div>
  </form>
</div>