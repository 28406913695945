<div *ngIf="products!=undefined && products.length<=0" class="text-center">
  <strong>{{ 'FE_General_Label_NoResult' | translate }}</strong>
</div>
<div class="mt-3 table-responsive table-wraper" [ngClass]="( products==undefined || products.length<=0)?'visible-table':'hide-table'">
  <table class="table table-hover">
    <thead>
      <tr>
        <th>{{ 'FE_General_Lable_Code' | translate }}</th>
        <th>{{ 'FE_General_Lable_Name' | translate }}</th>
        <th class="text-center">{{ 'FE_General_Lable_Actions' | translate }}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let product of products; let i=index ">
        <td>{{product.code}}</td>
        <td>{{product.name}}</td>
        <td class="text-center td-action">
          <div class="actions">
            <div *ngIf="isActionShown('edit')" class="action action-edit">
              <div class="btn-group">
                <button class="btn custom-btn" [routerLink]="['/products/', product.id, 'general']">
                  <img src="../../../../assets/images/edit.svg" class="Combined-Shape">
                </button>
                <span class="line-sperator"></span>
                <button class="btn custom-btn" disabled>
                  <img class="Combined-Shape delete-opacity" src="../../../../assets/images/delete.svg">
                </button>
              </div>
            </div>
            <div *ngIf="isActionShown('attach')" class="action action-attach">
              <button class="badge badge-light action-deactivate" (click)="confirmModel(content)" *ngIf="isAttached(product.id); else activateAction">{{ 'FE_General_Lable_DeActivate' | translate }}</button>
              <ng-template #activateAction>
                <button class="action-activate badge badge-primary" (click)="confirmModel(content)">{{ 'FE_General_Lable_Activate' | translate }}</button>
              </ng-template>
            </div>
          </div>
          <ng-template #content let-close="close" let-dismiss="dismiss">
            <div class="modal-header">
              <span class="delete-icon-back"><img src="../../../../assets/images/info.svg" class="delete-shape"></span>
              <span class="modal-title" *ngIf="isAttached(product.id); else deactivate">{{ 'FE_General_Lable_DeActivate' | translate }} Product</span>
              <ng-template #deactivate>
                <span class="modal-title">{{ 'FE_General_Lable_Activate' | translate }} Product</span>
              </ng-template>
              
              <button type="button" class="close" aria-label="Close" (click)="close('Cross click')">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <p *ngIf="isAttached(product.id); else showDeactivate">
                {{ 'FE_General_DeActivate_Message' | translate }}</p>
              <ng-template #showDeactivate>
                <p>
                  {{ 'FE_General_Activate_Message' | translate }}</p>
              </ng-template>
            </div>
            <div class="modal-footer">
              <div class="actions btn-toolbar mt-3 justify-content-end">
                <div class="mr-3">
                  <div (click)="addRemoveProduct(product.id)" class="btn-wrapper">
                    <button class="btn-custom-primary-modal" type="button" [disabled]="submitting"><label class="btn-lable-primary-modal">{{ 'FE_General_Button_Ok' | translate }}</label></button>
                  </div>
                </div>
                <div (click)="close('Close click')" class="btn-wrapper">
                  <button class="btn-custom-secondary-modal" type="button"><label class="btn-lable-secondary-modal">{{ 'FE_General_Button_Cancel' | translate }}</label></button>
                </div>
              </div>
            </div>
          </ng-template>
        </td>
      </tr>
    </tbody>
  </table>
</div>