import { Injectable } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { FormHelper } from '@core/services/FormHelper';
import { IUser, IProductClient, IAttachedRole } from '@modules/Users/user.model';
import { map } from 'lodash';
import { regexName } from '@config/constants';

@Injectable()
export class UserFormBuilder extends FormHelper<IUser> {

  createEmpty(): FormGroup {
    return this.fromModel({
      username: '',
      firstName: '',
      lastName: '',
      userProduct: [],
      isActive: true,
      phoneNumber: '',
      lastLogin: new Date(),
      changePasswordOnNextLogin: false,
      lockoutEnd: '1/1/0001 12:00:00 AM +00:00',
      lockoutEnabled: false,
      emailConfirmed: true,
      roles: [],
      loginType: 0,
      enableMFA: false,
    });
  }

  fromModel(model: Partial<IUser>): FormGroup {
    return this.builder.group({
      username: [model.username, [Validators.required,
      Validators.email,
      Validators.maxLength(75)],
      ],
      firstName: [model.firstName, [Validators.required,
      Validators.pattern(regexName),
      Validators.maxLength(75)],
      ],
      lastName: [model.lastName, [Validators.required,
      Validators.pattern(regexName),
      Validators.maxLength(75)],
      ],
      isActive: [model.isActive],
      phoneNumber: [model.phoneNumber, [
        Validators.required,
        Validators.pattern('^[0-9]*$'),
        Validators.maxLength(10)],
      ],
      lastLogin: [model.lastLogin],
      changePasswordOnNextLogin: [model.changePasswordOnNextLogin],
      loginType: [model.loginType],
      lockoutEnd: [model.lockoutEnd],
      lockoutEnabled: [model.lockoutEnabled],
      emailConfirmed: [model.emailConfirmed],
      enableMFA: [model.enableMFA],
      roles: [model.roles],
      userProduct:
        this.builder.array(
          map(model.userProduct, (product: IProductClient) => this.builder.group({
            id: product.id || '',
            name: product.name || '',
            checked: product.checked || false,
            roles:
              this.builder.array(
                map(product.roles, (attachedRole: IAttachedRole) => this.builder.group({
                  code: attachedRole.code || '',
                  name: attachedRole.name || '',
                  checked: attachedRole.checked || false,
                })),
              ),
          })),
        ),
    });
  }
}
