import { find } from 'lodash';
import { Injectable } from '@angular/core';
import { DataService } from '@core/services/DataService';
import { Endpoints } from '@config/endpoints';
import { ProductService } from '@modules/Product/services/ProductService';
import { IRolesUploadResponse } from '@modules/Roles/role.model';
import { IServerResponse } from '@core/core.interfaces';
import { Subject } from 'rxjs/internal/Subject';

@Injectable({
  providedIn: 'root',
})
export class RoleService {
  public rolesStatus = new Subject<any>();

  constructor( private dataService: DataService, private productService: ProductService) { }

  async getRoleById(productId: string): Promise<IRolesUploadResponse> {
    const { records } = await this.productService.getAll(1, '', productId);
    const product = find(records, { id: productId });
    if (!product) {
        throw new Error('Product not found');
    }

    const data = await this.dataService.request<IServerResponse<IRolesUploadResponse>>(Endpoints.GetRoles, {
        links: product.links,
    });
    this.rolesStatus.next(data.result);
    return data.result;
  }

  async update(model: FormData, productId: string): Promise<IRolesUploadResponse> {
    const { records } = await this.productService.getAll(1, '', productId);
    const product = find(records, { id: productId });
    if (!product) {
      throw new Error('Product not found');
    }
    const result = await this.dataService.request<IRolesUploadResponse>(Endpoints.UpdateRoles, {
      links: product.links,
      data: model,
    });
    return result;
  }
}
