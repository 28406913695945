import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { IdentityClientFormBuilder } from '@modules/IdentityServer/services/IdentityClientFormBuildur';
import { IdentityClientService } from '@modules/IdentityServer/services/IdentityClientService';
import { FormGroup } from '@angular/forms';
import { IIdentityServerClientResponse } from '@modules/IdentityServer/identityServer.model';
import {
  EditFormIdentityServerClientComponent,
} from '@modules/IdentityServer/component/edit-form-identity-server-client/edit-form-identity-server-client.component';
import { ExcepionHandlingService } from '@core/services/ExceptionHandlingService';

@Component({
  selector: 'sf-app-identityserverclients-create',
  templateUrl: './identity-server-clients-create.component.html',
  styleUrls: ['./identity-server-clients-create.component.scss'],
})
export class IdentityClientsCreateComponent implements OnInit {
  public submitting = false;
  public error: any = null;
  public form: FormGroup;
  public client: IIdentityServerClientResponse;
  public showMsg = false;
  @ViewChild(EditFormIdentityServerClientComponent) formComponent: EditFormIdentityServerClientComponent;

  constructor(
    private service: IdentityClientService,
    private formBuilder: IdentityClientFormBuilder,
    private router: Router,
    private exception: ExcepionHandlingService,
  ) { }

  ngOnInit() {
    this.createForm();
  }

  async createForm() {
    this.form = this.formBuilder.createEmpty();
  }

  async onSubmit() {
    this.formComponent.removeItem();
    if (this.form.invalid) {
      this.showMsg = true;
      return;
    }
    const client = this.formBuilder.toModel(this.form);
    try {
      this.error = null;
      this.submitting = true;
      await this.service.createIdentityServerClient(client);
      this.router.navigate(['identity-clients']);
    } catch (error) {
      this.error = this.exception.errorDisplay(error);
    } finally {
      this.submitting = false;
    }
  }

}
