<div class="col-md-12" *ngIf="form">
  <div class="row">
    <fieldset class="col-md-12 col-lg-5 mt-5">
      <legend>Redirect Uris</legend>
      <div class="text-right mb-2">
        <button type="button" class="btn custom-btn ft-1" (click)="addItem('redirectUris')">
          <i aria-hidden="true" class="fa fa-plus"></i> Add</button>
      </div>
      <div class="table-responsive table-wraper">
      <table class="table table-hover">
        <thead>
          <tr>
            <th class="w-100">Redirect Uris</th>
            <th class="text-center">Actions</th>
          </tr>
        </thead>
        <tbody [formGroup]=form>
          <ng-container formArrayName="redirectUris" *ngFor="let redirectUri of form.get('redirectUris').controls; let i = index;">
            <tr *ngIf=" redirectUri.get('deleteOperation').value ">
              <td>
                <span *ngIf="editId==i && showSaveRedirectUris" class="inputPadding">
                  <input type="text" [formControl]="redirectUri.controls['redirectUri']" class="form-control w-100"  [ngClass]="{ 'is-invalid': ((redirectUri.controls.redirectUri.dirty || redirectUri.controls.redirectUri.touched) && redirectUri.controls.redirectUri.errors || redirectUri.controls.redirectUri.untouched && validationMsg && redirectUri.controls.redirectUri.invalid) || duplicateNameValidation}">
                  <div *ngIf="(redirectUri.controls.redirectUri.dirty || redirectUri.controls.redirectUri.touched) && redirectUri.controls.redirectUri.errors || redirectUri.controls.redirectUri.untouched && validationMsg && redirectUri.controls.redirectUri.invalid" class="table-validation-msg">
                    <div *ngIf="redirectUri.controls.redirectUri.errors?.required">Redirect url is required</div>
                    <div *ngIf="redirectUri.controls.redirectUri.errors?.maxlength && !redirectUri.controls.redirectUri.errors.pattern">Should not be more than 2000 characters</div>
                    <div *ngIf="redirectUri.controls.redirectUri.errors?.pattern">Please enter valid url </div>
                  </div>
                  <div class="table-validation-msg" *ngIf="duplicateIdentifier('redirectUri')">Redirect url already exits</div>
                </span>       
                <span *ngIf="editId!==i || !showSaveRedirectUris" max="35" title="{{ redirectUri.get('redirectUri').value }}">{{ redirectUri.get('redirectUri').value | slice:0:35 }}</span>
              </td>
              <td class="text-center">
                <div class="btn-group" *ngIf="editId===i; else editUri">
                  <div *ngIf="!showSaveRedirectUris; else editUri1">
                    <button class="btn custom-btn" type="button" (click)="toggle(i, 'redirectUris')"><img src="../../../../assets/images/edit.svg" class="Combined-Shape"></button>
                    <button class="btn custom-btn" type="button" (click)="confirmModal(content, i, 'redirectUris', 'deleteRedirectUrisIds')"><img src="../../../../assets/images/delete.svg" class="Combined-Shape"></button>
                  </div>
                  <ng-template #editUri1>
                    <div class="btn-group">
                      <button class="btn custom-btn" type="button" (click)="update()"><img src="../../../../assets/images/checked.svg" class="Combined-Shape"></button>
                      <button class="btn custom-btn" type="button" (click)="resetRedirectUri('redirectUris')"><img src="../../../../assets/images/cancel.svg" class="Combined-Shape"></button>
                    </div>
                  </ng-template>
                </div>
                <ng-template #editUri>
                  <div class="btn-group">
                    <button class="btn custom-btn" type="button" (click)="toggle(i, 'redirectUris')"><img src="../../../../assets/images/edit.svg" class="Combined-Shape"></button>
                    <button class="btn custom-btn" type="button" (click)="confirmModal(content, i, 'redirectUris', 'deleteRedirectUrisIds')"><img src="../../../../assets/images/delete.svg" class="Combined-Shape"></button>
                  </div>
                </ng-template>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
      </div>
    </fieldset>
    <fieldset class="col-md-12 col-lg-5 offset-md-0 offset-lg-2 mt-5">
      <legend>Post Logout Redirect Uris</legend>
      <div class="text-right mb-2">
        <button type="button" class="btn custom-btn ft-1" (click)="addItem('postLogoutRedirectUris')">
          <i aria-hidden="true" class="fa fa-plus"></i> Add</button>
      </div>
      <div class="table-responsive table-wraper">
      <table class="table table-hover">
        <thead>
          <tr>
            <th class="w-100">Post Logout Redirect Uris</th>
            <th class="text-center">Actions</th>
          </tr>
        </thead>
        <tbody [formGroup]=form>
          <ng-container formArrayName="postLogoutRedirectUris" *ngFor="let postLogoutRedirectUri of form.get('postLogoutRedirectUris').controls; let i = index;">
            <tr *ngIf=" postLogoutRedirectUri.get('deleteOperation').value">
              <td>
                <span *ngIf="editId==i && showSavePostRedirectUris" class="inputPadding">
                  <input type="text" [formControl]="postLogoutRedirectUri.controls['postLogoutRedirectUri']" class="form-control w-100" #input [ngClass]="{ 'is-invalid': ((postLogoutRedirectUri.controls.postLogoutRedirectUri.dirty || postLogoutRedirectUri.controls.postLogoutRedirectUri.touched) && postLogoutRedirectUri.controls.postLogoutRedirectUri.errors || postLogoutRedirectUri.controls.postLogoutRedirectUri.untouched && validationMsg && postLogoutRedirectUri.controls.postLogoutRedirectUri.invalid) || duplicateNameValidation}">
                  <div *ngIf="(postLogoutRedirectUri.controls.postLogoutRedirectUri.dirty || postLogoutRedirectUri.controls.postLogoutRedirectUri.touched) && postLogoutRedirectUri.controls.postLogoutRedirectUri.errors || postLogoutRedirectUri.controls.postLogoutRedirectUri.untouched && validationMsg && postLogoutRedirectUri.controls.postLogoutRedirectUri.invalid" class="table-validation-msg">
                    <div *ngIf="postLogoutRedirectUri.controls.postLogoutRedirectUri.errors?.required">Post redirect url is required</div>
                    <div *ngIf="postLogoutRedirectUri.controls.postLogoutRedirectUri.errors?.maxlength && !postLogoutRedirectUri.controls.postLogoutRedirectUri.errors.pattern">Should not be more than 2000 characters</div>
                    <div *ngIf="postLogoutRedirectUri.controls.postLogoutRedirectUri.errors?.pattern">Please enter valid url </div>
                  </div>
                  <div class="table-validation-msg" *ngIf="duplicateIdentifier('postLogoutRedirectUri')">Post redirect url already exits</div>
                </span>
                <span *ngIf="editId!==i || !showSavePostRedirectUris" max="35" title="{{ postLogoutRedirectUri.get('postLogoutRedirectUri').value }}">{{ postLogoutRedirectUri.get('postLogoutRedirectUri').value | slice:0:35 }}</span>
              </td>
              <td  class="text-center">
                <div class="btn-group" *ngIf="editId===i; else editPostUri">
                  <div *ngIf="!showSavePostRedirectUris; else editPostUri1">
                    <button class="btn custom-btn" type="button" (click)="toggle(i, 'postLogoutRedirectUris')"><img src="../../../../assets/images/edit.svg" class="Combined-Shape"></button>
                    <button class="btn custom-btn" type="button" (click)="confirmModal(content, i, 'postLogoutRedirectUris', 'deletePostLogoutRedirectUrisIds')"><img src="../../../../assets/images/delete.svg" class="Combined-Shape"></button>
                  </div>
                  <ng-template #editPostUri1>
                    <div class="btn-group">
                      <button class="btn custom-btn" type="button" type="button" (click)="update()"><img src="../../../../assets/images/checked.svg" class="Combined-Shape"></button>
                      <button class="btn custom-btn" type="button" (click)="resetRedirectUri('postLogoutRedirectUris')"><img src="../../../../assets/images/cancel.svg" class="Combined-Shape"></button>
                    </div>
                  </ng-template>
                </div>
                <ng-template #editPostUri>
                  <div class="btn-group">
                    <button class="btn custom-btn" type="button" (click)="toggle(i, 'postLogoutRedirectUris')"><img src="../../../../assets/images/edit.svg" class="Combined-Shape"></button>
                    <button class="btn custom-btn" type="button" (click)="confirmModal(content, i, 'postLogoutRedirectUris', 'deletePostLogoutRedirectUrisIds')"><img src="../../../../assets/images/delete.svg" class="Combined-Shape"></button>
                  </div>
                </ng-template>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
      </div>
    </fieldset>
  </div>
</div>
<div class="col-md-12" *ngIf="form">
  <div class="row">
    <fieldset class="col-md-12 col-lg-5 mt-5">
      <legend>Allowed Cors Origin</legend>
      <div class="text-right mb-2">
        <button type="button" class="btn custom-btn ft-1" (click)="addItem('allowedCorsOrigins')">
          <i aria-hidden="true" class="fa fa-plus"></i> Add</button>
      </div>
      <div class="table-responsive table-wraper">
        <table class="table table-hover">
          <thead>
            <tr>
              <th class="w-100">Allowed Cors Origin</th>
              <th class="text-center">Actions</th>
            </tr>
          </thead>
          <tbody [formGroup]=form>
            <ng-container formArrayName="allowedCorsOrigins" *ngFor="let allowedCorsOrigin of form.get('allowedCorsOrigins').controls; let i = index;">
              <tr *ngIf=" allowedCorsOrigin.get('deleteOperation').value ">
                <td>
                  <span *ngIf="editId==i && showSaveCorsOrigins" class="inputPadding">
                    <input type="text" [formControl]="allowedCorsOrigin.controls['origin']" class="form-control w-100" #input [ngClass]="{ 'is-invalid': ((allowedCorsOrigin.controls.origin.dirty || allowedCorsOrigin.controls.origin.touched) && allowedCorsOrigin.controls.origin.errors || allowedCorsOrigin.controls.origin.untouched && validationMsg && allowedCorsOrigin.controls.origin.invalid) || duplicateNameValidation}">
                    <div *ngIf="(allowedCorsOrigin.controls.origin.dirty || allowedCorsOrigin.controls.origin.touched) && allowedCorsOrigin.controls.origin.errors || allowedCorsOrigin.controls.origin.untouched && validationMsg && allowedCorsOrigin.controls.origin.invalid" class="table-validation-msg">
                      <div *ngIf="allowedCorsOrigin.controls.origin.errors.required">Origin url is required</div>
                      <div *ngIf="allowedCorsOrigin.controls.origin.errors?.maxlength && !allowedCorsOrigin.controls.origin.errors.pattern">Should not be more than 150 characters</div>
                      <div *ngIf="allowedCorsOrigin.controls.origin.errors?.pattern && !allowedCorsOrigin.controls.origin.errors?.required">Please enter valid url </div>
                    </div>
                    <div class="table-validation-msg" *ngIf="duplicateIdentifier('origin')">Origin already exits</div>
                  </span>
                  <span *ngIf="editId!==i || !showSaveCorsOrigins" max="35" title="{{ allowedCorsOrigin.get('origin').value }}">{{ allowedCorsOrigin.get('origin').value | slice:0:35 }}</span>
                </td>
                <td class="text-center">
                  <div class="btn-group" *ngIf="editId===i; else editCors">
                    <div *ngIf="!showSaveCorsOrigins; else editCors1">
                      <button class="btn custom-btn" type="button" (click)="toggle(i, 'allowedCorsOrigins')"><img src="../../../../assets/images/edit.svg" class="Combined-Shape"></button>
                      <button class="btn custom-btn" type="button" (click)="confirmModal(content, i, 'allowedCorsOrigins', 'deleteAllowedCorsOriginsIds')"><img src="../../../../assets/images/delete.svg" class="Combined-Shape"></button>
                    </div>
                    <ng-template #editCors1>
                      <div class="btn-group">
                        <button class="btn custom-btn" type="button" type="button" (click)="update()"><img src="../../../../assets/images/checked.svg" class="Combined-Shape"></button>
                        <button class="btn custom-btn" type="button" (click)="resetRedirectUri('allowedCorsOrigins')"><img src="../../../../assets/images/cancel.svg" class="Combined-Shape"></button>
                      </div>
                    </ng-template>
                  </div>
                  <ng-template #editCors>
                    <div class="btn-group">
                      <button class="btn custom-btn" type="button" (click)="toggle(i, 'allowedCorsOrigins')"><img src="../../../../assets/images/edit.svg" class="Combined-Shape"></button>
                      <button class="btn custom-btn" type="button" (click)="confirmModal(content, i, 'allowedCorsOrigins', 'deleteAllowedCorsOriginsIds')"><img src="../../../../assets/images/delete.svg" class="Combined-Shape"></button>
                    </div>
                  </ng-template>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </fieldset>
    <fieldset class="col-md-12 col-lg-5 offset-md-0 offset-lg-2 mt-5">
      <legend>Allowed Grant Types</legend>
      <div class="mb-3 row ">
        <div class="pt-2 col-md-3">
          <label for="grant">Predefined</label>
        </div>
        <div class="col-md-7">
          <select id="grant" class="dropdown-control" #grantSelect>
            <option *ngFor="let grantType of predefinedGrantTypes" [value]="grantType.value">{{grantType.value}}</option>
          </select>
        </div>
        <div class="col-md-2">
          <button type="button" class="btn-set-grant" (click)="editGrantTypesModal(contentGrant, grantSelect)">Set</button>
        </div>
      </div>
      <div class="text-right mb-2">
        <button type="button" class="btn custom-btn ft-1" (click)="addItem('allowedGrantTypes')">
          <i aria-hidden="true" class="fa fa-plus"></i> Add</button>
      </div>
      <div class="table-responsive table-wraper">
        <table class="table table-hover">
          <thead>
            <tr>
              <th class="w-100">Grant Type</th>
              <th class="text-center">Actions</th>
            </tr>
          </thead>
          <tbody [formGroup]=form>
            <ng-container formArrayName="allowedGrantTypes" *ngFor="let allowedGrantType of form.get('allowedGrantTypes').controls; let i = index;">
              <tr *ngIf=" allowedGrantType.get('deleteOperation').value ">
                <td>
                  <span *ngIf="editId==i && showSaveGrantTypes">
                    <input type="text" [formControl]="allowedGrantType.controls['grantType']" class="form-control w-100" #input [ngClass]="{ 'is-invalid': ((allowedGrantType.controls.grantType.dirty || allowedGrantType.controls.grantType.touched) && allowedGrantType.controls.grantType.errors || allowedGrantType.controls.grantType.untouched && validationMsg && allowedGrantType.controls.grantType.invalid) || duplicateNameValidation}">
                    <div *ngIf="(allowedGrantType.controls.grantType.dirty || allowedGrantType.controls.grantType.touched) && allowedGrantType.controls.grantType.errors || allowedGrantType.controls.grantType.untouched && validationMsg && allowedGrantType.controls.grantType.invalid" class="table-validation-msg">
                      <div *ngIf="allowedGrantType.controls.grantType.errors.required">Grant type is required</div>
                      <div *ngIf="allowedGrantType.controls.grantType.errors?.maxlength && !allowedGrantType.controls.grantType.errors.pattern">Should not be more than 250 characters</div>
                    </div>
                    <div class="table-validation-msg" *ngIf="duplicateIdentifier('grantType')">Grant type already exits</div>
                  </span>
                  <span *ngIf="editId!==i || !showSaveGrantTypes" max="35" title="{{ allowedGrantType.get('grantType').value }}">{{ allowedGrantType.get('grantType').value | slice:0:35 }}</span>
                </td>
                <td class="text-center">
                  <div class="btn-group" *ngIf="editId===i; else editCors">
                    <div class="btn-group" *ngIf="!showSaveGrantTypes; else editCors1">
                      <button class="btn custom-btn" type="button" (click)="toggle(i, 'allowedGrantTypes')"><img src="../../../../assets/images/edit.svg" class="Combined-Shape"></button>
                      <button class="btn custom-btn" type="button" (click)="confirmModal(content, i, 'allowedGrantTypes', 'deleteAllowedGrantTypesIds')"><img src="../../../../assets/images/delete.svg" class="Combined-Shape"></button>
                    </div>
                    <ng-template #editCors1>
                      <div class="btn-group">
                        <button class="btn custom-btn" type="button" type="button" (click)="update()"><img src="../../../../assets/images/checked.svg" class="Combined-Shape"></button>
                        <button class="btn custom-btn" type="button" (click)="resetRedirectUri('allowedGrantTypes')"><img src="../../../../assets/images/cancel.svg" class="Combined-Shape"></button>
                      </div>
                    </ng-template>
                  </div>
                  <ng-template #editCors>
                    <div class="btn-group">
                      <button class="btn custom-btn" type="button" (click)="toggle(i, 'allowedGrantTypes')"><img src="../../../../assets/images/edit.svg" class="Combined-Shape"></button>
                      <button class="btn custom-btn" type="button" (click)="confirmModal(content, i, 'allowedGrantTypes', 'deleteAllowedGrantTypesIds')"><img src="../../../../assets/images/delete.svg" class="Combined-Shape"></button>
                    </div>
                  </ng-template>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </fieldset>
  </div>
</div>
  

<ng-template #contentGrant let-close="close" let-dismiss="dismiss">
  <div class="modal-header">
    <span class="delete-icon-back"><img src="../../../../assets/images/info.svg" class="delete-shape"></span>
    <span class="modal-title">Replace Grant Types</span>
    <button type="button" class="close" aria-label="Close" (click)="close('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p> Are you sure you want to replace all Grant types?</p>
  </div>
  <div class="modal-footer">
    <button class="btn-custom-primary-modal mr-3" type="button" (click)="setPredefined()"><label (click)="setPredefined()" class="btn-lable-primary-modal">{{ 'FE_General_Button_Ok' | translate }}</label></button>
    <button class="btn-custom-secondary-modal mr-3" type="button" (click)="close('Close click')"><label (click)="close('Close click')" class="btn-lable-secondary-modal">{{ 'FE_General_Button_Cancel' | translate }}</label></button>
  </div>
</ng-template>
<ng-template #content let-close="close" let-dismiss="dismiss">
  <div class="modal-header">
    <span class="delete-icon-back"><img src="../../../../assets/images/delete.svg" class="delete-shape"></span><span class="modal-title">Delete Item</span>
    <button type="button" class="close" aria-label="Close" (click)="close('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p> Are you sure you want to delete this item?</p>
  </div>
  <div class="modal-footer">
    <div class="actions btn-toolbar mt-3 justify-content-end">
      <div class="mr-3">
        <div (click)="deleteRedirectUri()" class="btn-wrapper">
          <button class="btn-custom-primary-modal" type="button" [disabled]="submitting"><label class="btn-lable-primary-modal">{{ 'FE_General_Button_Ok' | translate }}</label></button>
        </div>
      </div>
      <div (click)="close('Close click')" class="btn-wrapper">
        <button class="btn-custom-secondary-modal" type="button"><label class="btn-lable-secondary-modal">{{ 'FE_General_Button_Cancel' | translate }}</label></button>
      </div>
    </div>
  </div>
</ng-template>