import { Component, OnInit, OnDestroy, ElementRef, ViewChild, AfterViewChecked } from '@angular/core';
import { ToastService, IToast } from './toast.service';
import { assignIn, isEqual, map } from 'lodash';
import { ITimerMap } from '@core/core.interfaces';
import { trigger, state, style, transition, animate } from '@angular/animations';

const TOAST_EXIT_DURATION = 300;

@Component({
  animations: [
    trigger('fadeAnimation', [
      state('in', style({ opacity: 1 })),
      transition(':enter', []),
      transition(
        ':leave',
        animate(
          TOAST_EXIT_DURATION,
          style({
            opacity: 0,
            transform: 'translateY(10px) scale(.98)',
            height: 0,
            marginTop: 0,
          }),
        ),
      ),
    ]),
  ],
  selector: 'sf-toast-container',
  templateUrl: 'toast-container.component.html',
  styleUrls: ['toast-container.component.scss'],
})
export class ToastContainerComponent
  implements OnInit, OnDestroy, AfterViewChecked {
  @ViewChild('container') container: ElementRef;
  public toasts: IToast[] = [];
  public lastOrder: string[] = [];

  private timers: ITimerMap[] = [];

  constructor(private service: ToastService) {
    this.handleToast = this.handleToast.bind(this);
  }

  ngOnInit() {
    this.service.subscribe(this.handleToast);
  }

  ngAfterViewChecked() {
    const currentOrder = map(this.toasts, 'id');
    if (isEqual(currentOrder, this.lastOrder)) {
      return;
    }
    this.animate();
    this.lastOrder = currentOrder;
  }

  ngOnDestroy() {
    this.service.unsubscribe();
  }

  handleToast(toast: IToast) {
    this.toasts.push(toast);
    if (toast.autoclose) {
      this.createAutocloseTimer(toast);
    }
  }

  trackOrder(index: number, item: IToast) {
    return item.id;
  }

  animate() {
    const element = this.container.nativeElement as HTMLElement;
    if (!this.toasts.length) {
      element.style.transform = 'translateY(0)';
      return;
    }

    const children = element.querySelectorAll('.toast');
    const containerBounds = assignIn({}, element.getBoundingClientRect());
    const elementsBounds = Array.from(children).map((node: HTMLElement) => {
      const bounds = node.getBoundingClientRect();
      node.parentElement.style.height = bounds.height + 'px';
      return assignIn({}, bounds);
    });

    const distanceOffset = elementsBounds[0].top - containerBounds.top;
    const targetElementBounds = elementsBounds[this.toasts.length - 1];
    const offset =
      targetElementBounds.top -
      containerBounds.top +
      targetElementBounds.height +
      distanceOffset;

    const isRemoving = this.toasts.length < this.lastOrder.length;
    if (isRemoving) {
      element.style.transition = 'none';
    }

    window.setTimeout(() => {
      element.style.transform = `translateY(-${offset}px)`;
      element.style.transition = null;
    }, 0);
  }

  closeToast(toast: IToast) {
    const index = this.toasts.indexOf(toast);
    this.clearTimer(toast.id);
    this.toasts.splice(index, 1);
  }

  onClose(toast: IToast) {
    this.closeToast(toast);
  }

  private createAutocloseTimer(toast: IToast) {
    const timer = window.setTimeout(() => {
      this.closeToast(toast);
    }, toast.timeout);
    this.timers.push({
      id: toast.id,
      timer,
    });
  }

  private clearTimer(id: string) {
    const timerIndex = this.timers.findIndex((item: ITimerMap) => {
      return item.id === id;
    });
    if (timerIndex >= 0) {
      clearTimeout(this.timers[timerIndex].timer);
      this.timers.splice(timerIndex, 1);
    }
  }
}
