<div class="container-fluid">
  <h1>Client home page</h1>
  <h5 class="text-muted">Simplifai AS product</h5>
  <div class="pt-4">
    <fieldset class="scheduler-border">
        <legend class="scheduler-border">Available Products</legend>
        <div class="row">
          <div class="col-md-4 text-center" *ngFor="let data of productData">
            <a>
              <figure class="figure">
                <img src="{{data.src}}" class="figure-img img-fluid" alt="{{data.alt}}">
                <figcaption class="figure-caption">{{data.imgCaption}}</figcaption>
              </figure>
            </a>
          </div>
        </div>
      </fieldset> 
  </div>  
</div>