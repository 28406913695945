import { Component, OnInit } from '@angular/core';
import { ProductService } from '@modules/Product/services/ProductService';
import { Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { ProductFormBuilder } from '@modules/Product/services/ProductFormBuilder';
import { ExcepionHandlingService } from '@core/services/ExceptionHandlingService';

@Component({
  selector: 'sf-product-create',
  templateUrl: './product-create.page.html',
  styleUrls: ['./product-create.page.scss'],
})
export class ProductCreatePageComponent implements OnInit {
  public disableLogoChange = false;
  public submitting = false;
  public error: any = null;
  public form: FormGroup;
  public showMsg = false;

  constructor(
    private service: ProductService,
    private formBuilder: ProductFormBuilder,
    private router: Router,
    private exception: ExcepionHandlingService,
  ) { }

  ngOnInit() {
    this.createForm();
  }

  async createForm() {
    this.form = this.formBuilder.createEmpty();
  }

  async onSubmit() {
    if (this.form.invalid) {
      this.showMsg = true;
      return;
    }

    const product = this.formBuilder.toModel(this.form);
    try {
      this.error = null;
      this.submitting = true;
      const result = await this.service.create(product);
      this.router.navigate(['products', result.result.id, 'general']);
    } catch (error) {
      this.error = this.exception.errorDisplay(error);
    } finally {
      this.submitting = false;
    }
  }
}
