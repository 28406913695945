import { Component, OnInit } from '@angular/core';
import { DataService } from '@core/services/DataService';

@Component({
  templateUrl: 'dashboard.page.html',
  styleUrls: [ 'dashboard.page.scss' ],
})
export class DashboardPageComponent implements OnInit {

  public data: any[];

  constructor(private dataService: DataService) {}

  async ngOnInit() {
    this.data = await this.dataService.get<any[]>('/identity');
  }

}
