import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, AbstractControl, FormArray } from '@angular/forms';

@Component({
  selector: 'sf-app-user-edit-roles-products',
  templateUrl: './user-edit-roles-products.component.html',
  styleUrls: ['./user-edit-roles-products.component.scss'],
})
export class UserEditRolesProductsComponent implements OnInit {
  @Input() formUser: FormGroup;
  public userProduct: AbstractControl[];
  constructor() { }

  ngOnInit() {
    const userProduct = <FormArray>this.formUser.controls['userProduct'];
    this.userProduct = userProduct.controls;
  }

  isProductRolesDisabled(index: number) {
    return !this.formUser.value.userProduct[index].checked;
  }

}
