import { Injectable, EventEmitter } from '@angular/core';
import { Generator } from '@core/utils/Generator';
import { DEFAULT_TOAST_TIMEOUT } from '@config/constants';

export enum ToastType {
  Success = 'success',
  Warning = 'warning',
  Info = 'info',
  Error = 'error',
}

@Injectable()
export class ToastService {

  private change = new EventEmitter<IToast>();

  notify(data: IToast) {
    this.change.emit(data);
  }

  subscribe(callback: (toast: IToast) => any) {
    this.change.subscribe(callback);
  }

  unsubscribe() {
    this.change.unsubscribe();
  }

  success(data: IToastParams) {
    this.createNotification(data, ToastType.Success);
  }

  warning(data: IToastParams) {
    this.createNotification(data, ToastType.Warning);
  }

  info(data: IToastParams) {
    this.createNotification(data, ToastType.Info);
  }

  error(data: IToastParams) {
    this.createNotification(data, ToastType.Error);
  }

  private createNotification(data: IToastParams, type: ToastType) {
    const autoclose = data.autoclose === undefined ? true : data.autoclose;
    this.notify({
      id: Generator.randomKey(),
      type,
      autoclose,
      timeout: autoclose ? DEFAULT_TOAST_TIMEOUT : Infinity,
      ...data,
    });
  }

}

export interface IToastParams {
  title: string;
  subtitle?: string;
  footer?: string;
  timeout?: number;
  autoclose?: boolean;
}

export interface IToast extends IToastParams {
  id: string;
  type: ToastType;
}
