import { Injectable } from '@angular/core';
import { IClientSelect } from '@modules/AccessManager/accessManager.model';

@Injectable()
export class ClientSelectService {

  constructor() { }

  async getAll(): Promise<IClientSelect[]> {
    const result = require('../../../assets/data/data.json');
    return result;
  }
}
