import { Injectable } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { FormHelper } from '@core/services/FormHelper';
import { IApiResource, IApiSecret, IScope } from '@modules/IdentityServer/identityServer.model';
import { StringHelper } from '@core/utils/StringHelper';
import { map } from 'lodash';

@Injectable()
export class ApiResourceFormBuilder extends FormHelper<IApiResource> {

  createEmpty(): FormGroup {
    return this.fromModel({
      name: '',
      displayName: '',
      description: '',
      enabled: true,
      apiSecrets: [],
      scopes: [],
      userClaims: [],
      deleteApiSecretResourceIds: [],
      deleteApiScopeResourceIds: [],
    });
  }

  fromModel(model: Partial<IApiResource>): FormGroup {
    return this.builder.group({
      id: [model.id || 0],
      name: [model.name, [
        Validators.required,
        Validators.pattern('[0-9A-Za-z_-]+'),
        Validators.maxLength(75)],
      ],
      displayName: [model.displayName, [
        Validators.required,
        Validators.maxLength(75)],
      ],
      description: [model.description,  [Validators.maxLength(1000)]],
      enabled: [model.enabled],
      userClaims: [model.userClaims],
      apiSecrets: this.builder.array(
        map(model.apiSecrets, (secret: IApiSecret) => this.createSecrets(secret))),
      scopes: this.builder.array(
        map(model.scopes, (scope: IScope) => this.createScopes(scope))),
        deleteApiSecretResourceIds: [model.deleteApiSecretResourceIds],
        deleteApiScopeResourceIds: [model.deleteApiScopeResourceIds],
    });
  }

  map(form: FormGroup, model: IApiResource): IApiResource {
    super.map(form, model);
    model.userClaims = StringHelper.toArray(model.userClaims);
    return model;
  }

  createSecrets(secret: any): FormGroup {
    return this.builder.group({
      id: secret.id || 0,
      description: [secret.description || '', [Validators.maxLength(1000)]],
      value: [secret.value || '', [Validators.required, Validators.minLength(0), Validators.maxLength(75)]],
      expiration: new Date(secret.expiration || new Date()),
      type: [secret.type || '',  [Validators.required, Validators.minLength(0), Validators.maxLength(75)]],
      deleteOperation: 1,
    });
  }

  createScopes(scope: any): FormGroup {
    return this.builder.group({
      id: scope.id || 0,
      name: [scope.name || '',  [Validators.required, Validators.minLength(0), Validators.maxLength(75)]],
      displayName: [scope.displayName || '',  [Validators.required, Validators.minLength(0), Validators.maxLength(75)]],
      description: [scope.description || '', [Validators.maxLength(1000)]],
      required: scope.required || false,
      emphasize: scope.emphasize || false,
      showInDiscoveryDocument: scope.showInDiscoveryDocument || false,
      userClaims: scope.userClaims || [],
      deleteOperation: 1,
    });
  }

}
