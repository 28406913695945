import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ProductService } from '@modules/Product/services/ProductService';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'sf-product-edit-form',
  templateUrl: './product-edit-form.component.html',
  styleUrls: ['./product-edit-form.component.scss'],
})
export class ProductEditFormComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() showMsg: boolean;
  @Input() productId: string;
  @Input() pageNumber: number;
  @Input() disableLogoChange: boolean;
  @Input() imagePath: any;
  @Output() loadingToggle = new EventEmitter<void>();
  public imageUrl: any = 'assets/upload.png';
  public fileToUpload: File = null;
  @Input() error: any;
  constructor(private service: ProductService, private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.load();
  }

  get controls() {
    return this.form.controls;
  }

  async load() {
    if (this.productId && this.pageNumber) {
      const result = await this.service.getById(this.productId);
      this.imageUrl = (result.logoImage === '' ? 'assets/upload.png' : 'data:image/png;base64,' + result.logoImage);
    }
  }

  // Showing the selected image preview
  handleFileInput(file: FileList) {
    if (this.disableLogoChange) {
      this.fileToUpload = file.item(0);
      const reader = new FileReader();
      this.imagePath = null;
      reader.onload = (event: any) => {
        this.imageUrl = this.sanitizer.bypassSecurityTrustResourceUrl(event.target.result);
      };
      reader.readAsDataURL(this.fileToUpload);
    }
  }

  async upload() {
    try {

      if (this.fileToUpload) {
        this.loadingToggle.emit();
        const formData = new FormData();
        formData.append('file', this.fileToUpload);
        await this.service.updateLogoImage(formData, this.productId, this.pageNumber);
        this.loadingToggle.emit();
        this.fileToUpload = null;
      }
    } catch (err) { }
  }
}
