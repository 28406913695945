import { IRecord, ILink } from '@core/core.interfaces';

export interface IUser extends IRecord {
  username: string;
  firstName: string;
  lastName: string;
  isActive: boolean;
  lastLogin: any;
  email: string;
  phone: string;
  phoneNumber: string;
  changePasswordOnNextLogin: boolean;
  lockoutEnd: any;
  lockoutEnabled: boolean;
  emailConfirmed: boolean;
  roles: string[];
  userProduct: IProductClient[];
  loginType: LoginType;
  enableMFA: boolean;
}

export interface IUserResponse extends IRecord {
  username: string;
  firstName: string;
  lastName: string;
  isActive: boolean;
  lastLogin: string;
  email: string;
  phone: string;
  phoneNumber: string;
  changePasswordOnNextLogin: boolean;
  lockoutEnd: string;
  lockoutEnabled: boolean;
  emailConfirmed: boolean;
  roles: string[];
  userProduct: IProductClient[];
  loginType: LoginType;
  enableMFA: boolean;
}

export interface IProductClient {
  id: string;
  name: string;
  checked: boolean;
  roles: IAttachedRole[];
}

export interface IAttachedRole {
  code: string;
  name: string;
  checked: boolean;
}

export class User implements IUser {

  id: string;
  username: string;
  firstName: string;
  lastName: string;
  lastLogin: string;
  isActive: boolean;
  email: string;
  phone: string;
  phoneNumber: string;
  changePasswordOnNextLogin: boolean;
  lockoutEnd: string;
  lockoutEnabled: boolean;
  emailConfirmed: boolean;
  roles: string[];
  userProduct: IProductClient[];
  links?: ILink[];
  loginType: LoginType;
  enableMFA: boolean;

  constructor(data: IUser) {
    this.id = data.id;
    this.username = data.username;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.lastLogin = data.lastLogin;
    this.isActive = data.isActive;
    this.email = data.email;
    this.phone = data.phone;
    this.phoneNumber = data.phoneNumber;
    this.lockoutEnabled = data.lockoutEnabled;
    this.changePasswordOnNextLogin = data.changePasswordOnNextLogin;
    this.lockoutEnd = data.lockoutEnd;
    this.emailConfirmed = data.emailConfirmed;
    this.roles = data.roles;
    this.userProduct = data.userProduct ? [...data.userProduct] : [];
    this.links = data.links ? [...data.links] : [];
    this.loginType = data.loginType;
    this.enableMFA = data.enableMFA;
  }

  get name() {
    return `${this.firstName} ${this.lastName} (@${this.username})`;
  }
}

export enum LoginType {
  Regular = 0,
  Microsoft = 1,
  Google = 2,
}
