import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { IIdentityServerClientResponse } from '@modules/IdentityServer/identityServer.model';
import {
  IdentityClientsFormUrlsComponent,
} from '@modules/IdentityServer/component/identity-clients-form-urls/identity-clients-form-urls.component';
import {
  IdentityClientsFormScopesSecretsComponent,
} from '@modules/IdentityServer/component/identity-clients-form-scopes-secrets/identity-clients-form-scopes-secrets.component';

@Component({
  selector: 'sf-app-edit-form-identityserverclient',
  templateUrl: './edit-form-identity-server-client.component.html',
  styleUrls: ['./edit-form-identity-server-client.component.scss'],
})
export class EditFormIdentityServerClientComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() client: IIdentityServerClientResponse;
  @Input() showFields: boolean;
  @Input() showMsg: boolean;
  @ViewChild(IdentityClientsFormUrlsComponent) url: IdentityClientsFormUrlsComponent;
  @ViewChild(IdentityClientsFormScopesSecretsComponent) scope: IdentityClientsFormScopesSecretsComponent;
  public protocolTypes = [
    {key: 'oidc', value: 'oidc'},
    {key: 'wsfed', value: 'WsFederation'},
    {key: 'Saml2p', value: 'Saml2p'},
  ];
  @Input() error: any;
  constructor() { }

  ngOnInit() {
  }

  resetRedirectUri() {
    if (this.scope !== undefined) {
      this.scope.resetRedirectUri();
    } else if (this.url !== undefined) {
      this.url.resetRedirectUri();
    }
  }

  removeItem() {
    if (this.scope !== undefined) {
      this.scope.removeItem();
    } else if (this.url !== undefined) {
      this.url.removeItem();
    }
  }
}
