import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IToast } from './toast.service';

@Component({
  selector: 'sf-toast-message',
  templateUrl: 'toast-message.component.html',
  styleUrls: ['toast-message.component.scss'],
})
export class ToastMessageComponent {
  @Input() toast: IToast;
  @Output() close = new EventEmitter<IToast>();

  onClose() {
    this.close.emit();
  }
}
