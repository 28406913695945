import { Component, OnInit } from '@angular/core';
import { IIdentityServerClientResponse } from '@modules/IdentityServer/identityServer.model';
import { IdentityClientService } from '@modules/IdentityServer/services/IdentityClientService';
import { IPageInfoResponse } from '@core/core.interfaces';
import { ExcepionHandlingService } from '@core/services/ExceptionHandlingService';

@Component({
  selector: 'sf-app-identityserverclients',
  templateUrl: './identity-server-clients.component.html',
  styleUrls: ['./identity-server-clients.component.scss'],
})
export class IdentityClientsListComponent implements OnInit {
  public loading = false;
  public error: any = null;
  public clients: IIdentityServerClientResponse[];
  public pageInfo: IPageInfoResponse;
  public pageNumbers: any;
  public currentPageNumber = 1;
  public collectionSize: number;
  public totalPages: number;
  public searchText: string;

  constructor(
    private service: IdentityClientService,
    private exception: ExcepionHandlingService,
  ) { }

  ngOnInit() {
    this.loadIdentityClients(1);
  }

  async loadIdentityClients(pageNumber: number) {
    if (this.clients && this.clients.length === 1 && pageNumber !== 1) {
      pageNumber--;
      this.currentPageNumber = pageNumber;
    }
    try {
      this.loading = true;
      const result = await this.service.getAllIdentityServerClient(pageNumber, null, this.searchText);
      this.clients = result.records;
      this.pageInfo = result.pageInfo;
      this.collectionSize = this.pageInfo.totalRecords;
      this.totalPages = this.pageInfo.totalPages;
    } catch (err) {
      this.error = this.exception.errorDisplay(err);
    } finally {
      this.loading = false;
    }
  }

  search(search: string) {
    this.searchText = search;
    this.loadIdentityClients(1);
  }

}
