import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IProduct } from '@modules/Product/product.model';
import { CustomerService } from '@modules/Customer/services/CustomerService';
import { ProductService } from '@modules/Product/services/ProductService';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ICustomer } from '@modules/Customer/customer.model';
import { ExcepionHandlingService } from '@core/services/ExceptionHandlingService';

@Component({
  selector: 'sf-products-list',
  templateUrl: 'products-list.component.html',
  styleUrls: ['products-list.component.scss'],
})
export class ProductsListComponent {
  @Input() products: IProduct[];
  @Input() actions: string[];
  @Input() customer: ICustomer;
  @Output() update = new EventEmitter<void>();
  public loading: boolean;
  public modalReference: NgbModalRef;
  @Input() currentPage: number;
  public error: any;

  constructor(
    private customerService: CustomerService,
    private productService: ProductService,
    private modalService: NgbModal,
    private exception: ExcepionHandlingService,
  ) { }

  isAttached(id: string) {
    return this.productService.isAttached(id, this.customer);
  }

  confirmModel(content: any) {
    this.modalReference = this.modalService.open(content, { centered: true });
  }


  async addRemoveProduct(id: string) {
    const method = this.isAttached(id) ? 'removeProduct' : 'addProduct';
    try {
      this.loading = true;
      await this.customerService[method](this.customer, id);
    } catch (error) {
      this.error = this.exception.errorDisplay(error);
    } finally {
      this.loading = false;
      if (this.error === undefined) {
        if (this.isAttached(id)) {
          this.customer.products.splice(this.customer.products.findIndex((item: any) => item.id === id), 1);
        } else {
          this.customer.products.push({ id: id, name: '' });
        }
      }
    }
    this.modalReference.close();
  }

  isActionShown(action: string): boolean {
    return this.actions.indexOf(action) >= 0;
  }
}
