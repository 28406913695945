import { Injectable } from '@angular/core';
import { DataService } from '@core/services/DataService';
import { IApiResource, IHashResponse } from '@modules/IdentityServer/identityServer.model';
import { Endpoints } from '@config/endpoints';
import { find } from 'lodash';
import { IServerResponse, ILink, IServerCollectionResponse, ICollectionModel } from '@core/core.interfaces';

@Injectable()
export class ApiResourceService {

  public endpoints: ILink[];
  constructor(private dataService: DataService) { }

  async getAll(pageNumber: number, searchText: string = '', id: number = null): Promise<ICollectionModel<IApiResource>> {
    const data = await this.dataService.request<IServerCollectionResponse<IApiResource>>(Endpoints.GetApiResources, {
      query: {
        pageSize: 10,
        pageNumber: pageNumber,
        id: id,
        name: searchText,
      },
    });
    this.endpoints = data.result.links;
    return {
      records: data.result.records,
      pageInfo: data.pageInfo,
    };
  }

  async getById(id: number): Promise<IApiResource> {
    const { records } = await this.getAll(1, '', id);
    const resources = find(records, { id });
    if (!resources) {
      throw new Error('Client not found');
    }
    return resources;
  }

  async update(resource: IApiResource) {
    const data = await this.dataService.request<IServerResponse<IApiResource>>(Endpoints.UpdateApiResurces, {
      links: resource.links,
      data: resource,
    });
    return data;
  }

  async create(resource: IApiResource) {
    return this.dataService.request<IServerResponse<IApiResource>>(Endpoints.CreateApiResources, {
      data: resource,
    });
  }

  async updateHash() {
    return this.dataService.request<IServerResponse<IHashResponse>>(Endpoints.UpdateHash);
  }

  async delete(resource: IApiResource) {
    await this.dataService.request<IServerResponse<IApiResource>>(Endpoints.DeleteApiResource, {
      links: resource.links,
    });
  }

}
