<div class="page">
  <h1 class="table-heading">{{ 'FE_Headers_Label_Customers' | translate }}</h1>
  <div class="row">
    <div class="col-md-6">
      <a class="btn custom-btn" routerLink="/customers/new">
        <i class="fa fa-plus" aria-hidden="true"></i> {{ 'FE_General_Add' | translate }}</a>
    </div>
    <div class="col-md-6">
      <div class="search-box">
        <i class="fa fa-search fa-2x"></i>
        <input type="text" class="search" placeholder="{{ 'FE_General_Search_PlaceHolder' | translate }}" sfDebouncedInput (debouncedChange)="search($event)"
          [debounceTime]="700">
      </div>
    </div>
  </div>
  <sf-app-loading [loading]="loading"></sf-app-loading>
  <div *ngIf="customers">

    <sf-customer-list [customers]="customers" [currentPageNumber]="currentPageNumber" (update)="loadCustomers(currentPageNumber)"></sf-customer-list>
    <ngb-pagination *ngIf="totalPages > 1" class="d-flex justify-content-center" [collectionSize]="collectionSize" [(page)]="currentPageNumber"
      [maxSize]="5" (pageChange)="loadCustomers(currentPageNumber)"></ngb-pagination>
  </div>