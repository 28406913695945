import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { IIdentityServerClientResponse } from '@modules/IdentityServer/identityServer.model';
import { IdentityClientService } from '@modules/IdentityServer/services/IdentityClientService';
import { ExcepionHandlingService } from '@core/services/ExceptionHandlingService';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'sf-app-identityserverclient-list',
  templateUrl: './identity-server-client-list.component.html',
  styleUrls: ['./identity-server-client-list.component.scss'],
})
export class IdentityServerClientListComponent implements OnInit {
  @Input() clients: IIdentityServerClientResponse[];
  @Input() currentPageNumber: number;
  @Output() update = new EventEmitter<void>();
  @Input() error: any;
  public client: IIdentityServerClientResponse;
  public modalReference: NgbModalRef;

  constructor(
    private service: IdentityClientService,
    private exception: ExcepionHandlingService,
    private modalService: NgbModal,
  ) { }

  ngOnInit() {
  }

  confirmModal(content: any, client: IIdentityServerClientResponse) {
    this.client = client;
    this.modalReference = this.modalService.open(content, { centered: true });
  }

  async delete() {
    try {
      await this.service.delete(this.client);
      this.update.emit();
    } catch (error) {
      this.error = this.exception.errorDisplay(error);
    } finally {
      this.modalReference.close();
     }

  }

}
