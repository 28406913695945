<div class="page">
  <h1 class="table-heading">Client resources</h1>
  <div class="row pb-3">
    <div class="col-md-6">
      <a class="btn custom-btn" routerLink="/identity-clients/new">
        <i class="fa fa-plus" aria-hidden="true"></i> {{ 'FE_General_Add' | translate }}</a>
    </div>
    <div class="col-md-6">
      <div class="search-box">
        <i class="fa fa-search fa-2x"></i>
        <input type="text" class="search" placeholder="{{ 'FE_General_Search_PlaceHolder' | translate }}" sfDebouncedInput (debouncedChange)="search($event)"
          [debounceTime]="700">
      </div>
    </div>
  </div>

  <sf-app-loading [loading]="loading"></sf-app-loading>
  <div class="wrapper mt-3" *ngIf="clients">
    <sf-app-identityserverclient-list [clients]="clients" [currentPageNumber]="currentPageNumber" (update)="loadIdentityClients(currentPageNumber)"></sf-app-identityserverclient-list>
    <ngb-pagination *ngIf="totalPages > 1" class="d-flex justify-content-center" [collectionSize]="collectionSize" [(page)]="currentPageNumber"
      [maxSize]="5" (pageChange)="loadIdentityClients(currentPageNumber)"></ngb-pagination>
  </div>
</div>