import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IIdentityServerClientResponse } from '@modules/IdentityServer/identityServer.model';
import { IdentityClientService } from '@modules/IdentityServer/services/IdentityClientService';
import { IdentityClientFormBuilder } from '@modules/IdentityServer/services/IdentityClientFormBuildur';
import {
  EditFormIdentityServerClientComponent,
} from '@modules/IdentityServer/component/edit-form-identity-server-client/edit-form-identity-server-client.component';
import { ExcepionHandlingService } from '@core/services/ExceptionHandlingService';
@Component({
  selector: 'sf-app-identityserverclients-edit',
  templateUrl: './identity-server-clients-edit.component.html',
  styleUrls: ['./identity-server-clients-edit.component.scss'],
})
export class IdentityClientsEditComponent implements OnInit {
  public loading = false;
  public submitting = false;
  public error: any = null;
  public client: IIdentityServerClientResponse;
  public form: FormGroup;
  public allowedScopes: any;
  @ViewChild(EditFormIdentityServerClientComponent) formComponent: EditFormIdentityServerClientComponent;

  constructor(
    private service: IdentityClientService,
    private formBuilder: IdentityClientFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private exception: ExcepionHandlingService,
  ) { }

  get clientId() {
    return this.route.snapshot.params.id;
  }


  ngOnInit() {
    this.loadIdentityResourceClient();
  }

  async loadIdentityResourceClient() {
    try {
      this.error = null;
      this.loading = true;
      const client = await this.service.getIdentityClientByID(parseInt(this.clientId, 10));
      const form = this.formBuilder.fromModel(client);
      this.client = client;
      this.form = form;
    } catch (err) {
      this.error = this.exception.errorDisplay(err);
    } finally {
      this.loading = false;
    }
  }

  async onSubmit() {
    this.formComponent.removeItem();
    if (this.form.invalid) {
      return;
    }

    const newClient = this.formBuilder.map(this.form, this.client);
    try {
      this.error = null;
      this.loading = true;
      this.submitting = true;
      await this.service.updateIdentityServerClient(newClient);
      this.router.navigate(['identity-clients']);
    } catch (error) {
      this.error = this.exception.errorDisplay(error);
    } finally {
      this.loading = false;
      this.loading = false;
      this.submitting = false;
    }
  }

}
