import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { IdentityResourceFormBuilder } from '@modules/IdentityServer/services/IdentityResourceFormBuilder';
import { IdentityResourceService } from '@modules/IdentityServer/services/IdentityResourceService';
import {
  EditFormIdentityResourcesComponent,
} from '@modules/IdentityServer/component/edit-form-identity-resources/edit-form-identity-resources.component';
import { ExcepionHandlingService } from '@core/services/ExceptionHandlingService';

@Component({
  selector: 'sf-app-identityresources-create',
  templateUrl: './identity-resources-create.component.html',
  styleUrls: ['./identity-resources-create.component.scss'],
})
export class IdentityResourcesCreateComponent implements OnInit {
  public submitting = false;
  public error: any = null;
  public form: FormGroup;
  public showMsg = false;
  @ViewChild(EditFormIdentityResourcesComponent) formComponent: EditFormIdentityResourcesComponent;

  constructor(
    private service: IdentityResourceService,
    private formBuilder: IdentityResourceFormBuilder,
    private router: Router,
    private exception: ExcepionHandlingService,
  ) { }

  ngOnInit() {
    this.createForm();
  }

  async createForm() {
    this.form = this.formBuilder.createEmpty();
  }

  async onSubmit() {
    this.formComponent.removeItem();
    if (this.form.invalid) {
      this.showMsg = true;
      return;
    }

    const client = this.formBuilder.toModel(this.form);
    try {
      this.error = null;
      this.submitting = true;
      await this.service.createIdentityResource(client);
      this.router.navigate(['identity-resources']);
    } catch (error) {
      this.error = this.exception.errorDisplay(error);
    } finally {
      this.submitting = false;
    }
  }
}
