import { Injectable } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { FormHelper } from '@core/services/FormHelper';
import { ICustomer } from '@modules/Customer/customer.model';
import { customerRegexName } from '@config/constants';

@Injectable()
export class CustomerFormBuilder extends FormHelper<ICustomer> {

  createEmpty(): FormGroup {
    return this.fromModel({
      name: '',
      databaseName: '',
      connectionString: '',
      products: [],
      logoImage: '',
    });
  }

  fromModel(model: Partial<ICustomer>): FormGroup {
    return this.builder.group({
      name: [model.name, [
        Validators.required,
        Validators.pattern(customerRegexName),
        Validators.maxLength(75)],
      ],
      databaseName: [model.databaseName],
      connectionString: [model.connectionString],
      products: [model.products],
      logoImage: [model.logoImage],
    });
  }
}
