import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IApiResource } from '@modules/IdentityServer/identityServer.model';
import { FormGroup } from '@angular/forms';
import { ApiResourceFormBuilder } from '@modules/IdentityServer/services/ApiResourceFormBuilder';
import { ApiResourceService } from '@modules/IdentityServer/services/ApiResourceService';
import { ApiResourceEditComponent } from '@modules/IdentityServer/component/api-resource-edit/api-resource-edit.component';
import { ExcepionHandlingService } from '@core/services/ExceptionHandlingService';

@Component({
  templateUrl: './api-resource-edit.component.html',
  styleUrls: ['./api-resource-edit.component.scss'],
})
export class ApiResourceEditPageComponent implements OnInit {

  public loading = false;
  public submitting = false;
  public error: any = null;
  public resource: IApiResource;
  public form: FormGroup;
  @ViewChild(ApiResourceEditComponent) formComponent: ApiResourceEditComponent;

  constructor(
    private route: ActivatedRoute,
    private formBuilder: ApiResourceFormBuilder,
    private service: ApiResourceService,
    private router: Router,
    private exception: ExcepionHandlingService,
  ) { }

  ngOnInit() {
    this.loadIdentityResourceClient();
  }

  get apiResourceId(): number {
    return parseInt(this.route.snapshot.params.id, 10);
  }

  async loadIdentityResourceClient() {
    try {
      this.loading = true;
      const apiResources = await this.service.getById(this.apiResourceId);
      const form = this.formBuilder.fromModel(apiResources);
      this.resource = apiResources;
      this.form = form;
    } catch (err) {
      this.error = this.exception.errorDisplay(err);
    } finally {
      this.loading = false;
    }
  }


  async onSubmit() {
    this.formComponent.removeItem();
    if (this.form.invalid) {
      return;
    }

    this.resource = this.formBuilder.map(this.form, this.resource);
    try {
      this.submitting = true;
      this.loading = true;
      await this.service.update(this.resource);
      this.router.navigate(['identity/api-resources']);
    } catch (error) {
      this.error = this.exception.errorDisplay(error);
    } finally {
      this.loading = false;
      this.submitting = false;
    }
  }
}
